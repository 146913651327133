import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon, Spacing, TagButton, Text } from '@reservamos/elements';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { updateFirstPassenger } from '@/actions/siemprePlus';
import { fieldRenderer } from 'utils/formRenderers';
import PassengerBusCategories from './PassengerBusCategories';
import PassengerInsuranceCheckbox from '../../PassengerInsuranceCheckbox';
import normalizeName from '../../../utils/normalizeName';
import normalizeEmail from '../../../utils/normalizeEmail';
import PassengersHeading from '../../../ui/molecules/PassengersHeading';
import LabelSwitch from '../../../ui/atoms/LabelSwitch';
import LoyaltyHolder from '../../../ui/atoms/LoyaltyHolder';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import usePurchase from '../../../hooks/store/usePurchase';
import parsePassenger from '../../../utils/purchase/parsePassenger';

const defaultPassenger = {
  firstName: '',
  lastName: '',
  category: 'adult',
  busCategory: 'general',
};

const propTypes = {
  fields: PropTypes.object.isRequired,
  autofillEnabled: PropTypes.bool.isRequired,
  updatingCategories: PropTypes.bool.isRequired,
  busCategories: PropTypes.array,
  toggleAutofill: PropTypes.func.isRequired,
  deletePassenger: PropTypes.func.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number,
  insuranceIncomingUnitAmount: PropTypes.number,
  isExchange: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  loyaltyHolderName: PropTypes.string,
  user: PropTypes.object,
  isLogged: PropTypes.bool,
  isAccountThePassenger: PropTypes.bool,
  dynamicInitialValues: PropTypes.array,
  autofillFirstPassenger: PropTypes.func,
};

const defaultProps = {
  busCategories: [],
  isExchange: false,
};

const DynamicPassengersForm = (props) => {
  const [scrollBottom, setScrollBottom] = useState(false);
  const [holderEditingEnabled, setHolderEditingEnabled] = useState(false);
  const initialFields = useRef(null);

  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('passengers');

  const { availableWallets = [], walletType } = usePurchase();
  const { userIsLoggedInWithAnyLoyaltyProgram } = useLoyaltyPrograms();

  const {
    fields,
    busCategories,
    autofillEnabled,
    updatingCategories,
    toggleAutofill,
    insuranceOutgoingUnitAmount,
    insuranceIncomingUnitAmount,
    isExchange,
    isOpenTicket,
    loyaltyHolderName,
    user,
    isLogged,
    isAccountThePassenger,
  } = props;

  const maxPassengersReached = fields.length >= features.SEAT_SELECTION_LIMIT;

  /**
   * Sets the initial values of passengers in a static ref array
   * This field is used for avoid render without initializing and for load initial data after authenticate
   */
  const setInitialFields = () => {
    initialFields.current = [...fields.getAll()];
  };

  useEffect(() => {
    if (!initialFields.current) setInitialFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { dynamicInitialValues, autofillFirstPassenger } = props;
    if (isLogged) {
      autofillFirstPassenger(
        dynamicInitialValues[0].firstName,
        dynamicInitialValues[0].lastName,
        dynamicInitialValues[0].secondLastName,
        dynamicInitialValues[0].documentType,
        dynamicInitialValues[0].documentId,
        dynamicInitialValues[0].email,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const onDeletePassenger = (index) => {
    const { fields, deletePassenger } = props;
    const passengerId = fields.get(index).id;

    if (passengerId) {
      deletePassenger(passengerId);
    }

    fields.remove(index);
  };

  const addPassenger = () => {
    const { fields } = props;
    if (maxPassengersReached) return;
    fields.push(defaultPassenger);
    setScrollBottom(true);
  };

  /**
   * Handles the name change switch click event.
   * When clicked, if disabled it sets default values for firstName and lastName
   * @param {SyntheticEvent} e - Click event
   */
  const handleHolderEditing = (e) => {
    if (!e.target.checked) {
      fields.get(0).firstName = initialFields.current[0]?.firstName;
      fields.get(0).lastName = initialFields.current[0]?.lastName;
    }
    setHolderEditingEnabled((prev) => !prev);
    if (holderEditingEnabled) {
      updateFirstPassenger(parsePassenger(user));
    }
  };

  useEffect(() => {
    if (!isLogged) return;
    if (isAccountThePassenger) {
      setHolderEditingEnabled(false);
    } else {
      setHolderEditingEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountThePassenger]);

  useEffect(() => {
    if (scrollBottom) {
      window.scrollTo(0, document.body.scrollHeight);
      setScrollBottom(false);
    }
  }, [scrollBottom]);

  const SHOW_PURCHASER_FORM = features.PURCHASER_FORM_ON === 'PASSENGERS';

  const passengers = fields.map((passenger, index) => {
    const passengerCategory = fields.get(index).category;
    const passengerFields = fields.get(index);
    const isFirstPassenger = index === 0;
    const fadeIn = isFirstPassenger ? '' : 'animated fade-in';
    const initialValues =
      initialFields.current && initialFields.current[index]
        ? initialFields.current[index]
        : passengerFields;

    const isDisabled = (field, fieldName, isFirstPassenger, loyaltyHolderName) => {
      const exchangeDisablingCondition =
        isExchange && !features.EXCHANGE_PASSENGERS_EDITABLE && field;

      const disableBecauseAutoFill = fieldName !== 'phone' && autofillEnabled && isFirstPassenger;

      const enabledEmailField = fieldName === 'email' && !features.SHOW_PASSENGERS_EMAIL_FIELD;

      const loyaltyDisablingCondition =
        availableWallets?.includes(walletType) &&
        !holderEditingEnabled &&
        userIsLoggedInWithAnyLoyaltyProgram &&
        isFirstPassenger &&
        loyaltyHolderName &&
        features.DISABLE_PASSENGER_LOGGED;

      return (
        loyaltyDisablingCondition ||
        exchangeDisablingCondition ||
        disableBecauseAutoFill ||
        enabledEmailField
      );
    };

    const canUseEditSwitch = {
      costapass: false,
      doters: false,
      siempreplus: true,
    };
    const showLoyaltyHolder =
      loyaltyHolderName && isFirstPassenger && userIsLoggedInWithAnyLoyaltyProgram;

    const showEditNameSwitch =
      showLoyaltyHolder && !isExchange && canUseEditSwitch[loyaltyHolderName] && isFirstPassenger;

    return (
      <div key={passenger} className={`passenger-info ${fadeIn}`}>
        <Spacing size="S" vertical>
          <PassengersHeading
            category="adult"
            title={t('label.passenger', { index: index + 1 })}
            rightContent={
              <>
                {!isFirstPassenger && !isExchange && (
                  <button type="button" onClick={() => onDeletePassenger(index)}>
                    <Text elementType="span" weight="semibold" size="S" color="error">
                      {t('common:buttons.delete')}
                    </Text>
                    <Icon type="Cross" color="error" size="XS" hasMargin marginSide="left" />
                  </button>
                )}
                {showLoyaltyHolder && (
                  <LoyaltyHolder
                    loyaltyHolderName={loyaltyHolderName}
                    hideLabel={holderEditingEnabled}
                  />
                )}
              </>
            }
          />

          <Spacing size="S" flexGrow>
            <Field
              name={`${passenger}.firstName`}
              id={`${passenger}.firstName`}
              type="text"
              placeholder={t('fore_name')}
              component={fieldRenderer}
              normalize={normalizeName}
              isDisabled={isDisabled(
                initialValues.firstName,
                'firstName',
                isFirstPassenger,
                loyaltyHolderName,
              )}
            />

            <Field
              name={`${passenger}.lastName`}
              id={`${passenger}.lastName`}
              type="text"
              placeholder={t('family_name_one')}
              component={fieldRenderer}
              normalize={normalizeName}
              isDisabled={isDisabled(
                initialValues.lastName,
                'lastName',
                isFirstPassenger,
                loyaltyHolderName,
              )}
            />
          </Spacing>

          {(features.SHOW_PASSENGERS_SECOND_LAST_NAME_FIELD ||
            features.SHOW_PASSENGERS_EMAIL_FIELD) && (
            <Spacing size="S" flexGrow>
              {features.SHOW_PASSENGERS_SECOND_LAST_NAME_FIELD && (
                <Field
                  name={`${passenger}.secondLastName`}
                  id={`${passenger}.secondLastName`}
                  type="text"
                  placeholder={`${t('family_name_two')} ${t('optional')}`}
                  component={fieldRenderer}
                  normalize={normalizeName}
                  isDisabled={isDisabled(
                    initialValues.secondLastName,
                    'secondLastName',
                    isFirstPassenger,
                    loyaltyHolderName,
                  )}
                />
              )}
              {features.SHOW_PASSENGERS_EMAIL_FIELD && (
                <Field
                  name={`${passenger}.email`}
                  id={`${passenger}.email`}
                  type="text"
                  placeholder={`${t('email')} ${index !== 0 ? t('optional') : ''}`}
                  normalize={normalizeEmail}
                  component={fieldRenderer}
                  isDisabled={isDisabled(
                    initialValues.email,
                    'email',
                    isFirstPassenger,
                    loyaltyHolderName,
                  )}
                />
              )}
            </Spacing>
          )}

          {showEditNameSwitch && (
            <LabelSwitch
              label={t('edit_name')}
              justifyContent="flex-end"
              checked={holderEditingEnabled}
              onChange={handleHolderEditing}
            />
          )}

          <PassengerBusCategories
            name={`${passenger}.busCategory`}
            passengerCategory={passengerCategory}
            isFirstPassenger={isFirstPassenger}
            busCategories={busCategories}
            isUpdating={updatingCategories}
            isDisabled={isExchange && !features.EXCHANGE_PASSENGERS_EDITABLE}
            passengerBusCategory={fields.get(index).busCategory}
            isOpenTicket={isOpenTicket}
          />

          {features.PASSENGERS_INSURANCE_ENABLED &&
            (insuranceIncomingUnitAmount > 0 || insuranceOutgoingUnitAmount > 0) && (
              <PassengerInsuranceCheckbox
                passenger={passenger}
                insuranceOutgoingUnitAmount={insuranceOutgoingUnitAmount}
                insuranceIncomingUnitAmount={insuranceIncomingUnitAmount}
              />
            )}
        </Spacing>
      </div>
    );
  });

  const showPurchaserPassengerSwitch = !isExchange && SHOW_PURCHASER_FORM;

  return (
    <Spacing vertical>
      <Spacing vertical size="XS">
        <div className="passenger-detail-title">
          <Text weight="bold" size="L">
            {t('label.passengers')}
          </Text>

          {!isExchange && (
            <TagButton onClick={addPassenger} isDisabled={maxPassengersReached}>
              {t('add_passenger')}
            </TagButton>
          )}
        </div>
        {showPurchaserPassengerSwitch && (
          <LabelSwitch
            checked={autofillEnabled}
            onChange={toggleAutofill}
            id="check-passengers"
            label={t('is_the_buyer_a_passenger')}
          />
        )}
      </Spacing>
      <Spacing vertical size="L">
        {passengers}
      </Spacing>

      {!isExchange && (
        <div className="add-passenger">
          <TagButton onClick={addPassenger} isDisabled={maxPassengersReached}>
            {t('add_passenger')}
          </TagButton>
        </div>
      )}
    </Spacing>
  );
};

DynamicPassengersForm.propTypes = propTypes;
DynamicPassengersForm.defaultProps = defaultProps;

export default DynamicPassengersForm;
