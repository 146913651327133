import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessageBox, Spacing, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import { shouldUseAdyen } from 'utils/adyen';
import CardForm from '../CardForm';
import PaypalForm from '../PaypalForm';
import KueskipayForm from '../KueskipayForm';
import AgencyForm from '../AgencyForm';
import StoreForm from '../StoreForm';
import ThirdPartyForm from '../ThirdPartyForm';
import AdyenForm from '../AdyenForm';
import PixForm from '../PixForm';
import FreePayForm from '../FreePayForm';
import ReservamosPayForm from '../ReservamosPayForm';
import SavedCards from '../SavedCards';
import ExchangePayForm from '../ExchangePayForm';
import MercadoPagoTDCForm from '../MercadoPagoTDCForm';
import availablePaymentEngines from '../../../constants/PaymentEngine';
import EfectyForm from '../EfectyForm/EfectyForm';
import TransferFormStrategy from './TransferFormStrategy';
import EvertecForm from '../EvertecForm';

const PaymentForm = ({
  formType,
  submitCardFail,
  submitCard,
  submitTransfer,
  selectedOption,
  cardErrorOccured,
  showSavedCards,
  showAdyenForm,
  showCheckbox,
  paymentEngine,
  purchaseTotal,
  isExchange,
}) => {
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));
  const { enabled: mercadoPagoIsEnabled } = useSelector(
    (state) => state.whitelabelConfig.env.mercadoPago,
  );
  const shouldUseMercadoPago =
    mercadoPagoIsEnabled && paymentEngine === availablePaymentEngines.mercadoPago;
  const shouldUseEvertec = selectedPaymentMethod?.engine === 'evertec';

  const { t } = useTranslation('purchase');

  let form;

  switch (formType) {
    case 'credit_card':
      form = (
        <Spacing vertical>
          <CardForm onSubmit={submitCard} onSubmitFail={submitCardFail} />
        </Spacing>
      );

      if (shouldUseAdyen(paymentEngine)) {
        form = (
          <div className="adyen-form">
            <Spacing vertical>
              {cardErrorOccured && (
                <MessageBox title={t('label.problems_paying')} borderColor="warning">
                  <Text size="S">{t('text.suggestions_to_payment_problems')}</Text>
                </MessageBox>
              )}
              {showSavedCards && <SavedCards />}
              <AdyenForm showAdyenForm={showAdyenForm} showCheckbox={showCheckbox} />
            </Spacing>
          </div>
        );
      }

      if (shouldUseMercadoPago) {
        form = <MercadoPagoTDCForm amount={purchaseTotal} />;
      }

      if (shouldUseEvertec) {
        form = <EvertecForm />;
      }

      break;
    case 'paypal':
      form = <PaypalForm />;
      break;
    case 'agency_pay':
      form = <AgencyForm />;
      break;
    case 'third_party':
      form = <ThirdPartyForm />;
      break;
    case 'reservamos_pay':
      form = <ReservamosPayForm />;
      break;
    case 'exchange':
      form = <ExchangePayForm />;
      break;
    case 'free_pay':
      form = <FreePayForm isExchange={isExchange} />;
      break;
    case 'transfer':
      form = (
        <TransferFormStrategy
          selectedPaymentMethod={selectedPaymentMethod}
          submitTransfer={submitTransfer}
        />
      );
      break;
    case 'store':
      form = <StoreForm />;
      break;
    case 'kueskipay':
      form = <KueskipayForm />;
      break;
    case 'pix':
      form = <PixForm />;
      break;
    case 'efecty':
      form = <EfectyForm />;
      break;
    default:
      form = <div />;
  }

  return (
    <div className="tab-content" id={formType}>
      {selectedOption === formType && form}
    </div>
  );
};

PaymentForm.propTypes = {
  formType: PropTypes.string.isRequired,
  selectedOption: PropTypes.string.isRequired,
  submitCard: PropTypes.func,
  submitCardFail: PropTypes.func,
  submitTransfer: PropTypes.func.isRequired,
  cardErrorOccured: PropTypes.bool,
  showSavedCards: PropTypes.bool,
  showAdyenForm: PropTypes.bool,
  paymentEngine: PropTypes.string,
  showCheckbox: PropTypes.bool,
  purchaseTotal: PropTypes.number.isRequired,
  isExchange: PropTypes.bool.isRequired,
};

PaymentForm.defaultProps = {
  cardErrorOccured: false,
  showSavedCards: false,
  showAdyenForm: false,
  showCheckbox: true,
};

export default PaymentForm;
