import { UserContext } from 'components/Auth0/UserProvider';
import { useContext } from 'react';

function useUser() {
  const contextValue = useContext(UserContext);
  if (!contextValue) throw new Error('UserContext value is undefined');
  return contextValue;
}

export default useUser;
