import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'styles/components/purchase/SeatsPreviewPricing';
import { Currency, Gap, Spacing, Text, Visibility } from '@reservamos/elements';
import SeatsButtonWrapper from 'ui/atoms/SeatsButtonWrapper';
import NewSeat from 'ui/atoms/NewSeat';
import PricingRow from 'ui/atoms/PricingRow';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { discountTypes } from 'models/parseTrip';
import { When } from 'react-if';
import { getTripPricing } from 'hooks/usePricingBeforeCheckout';
import PricingTooltipDetails from 'ui/atoms/PricingTooltipDetails';
import ToggleLabel from 'ui/molecules/ToggleLabel';
import 'styles/components/SeatsPreviewPricing';
import { isDesktopSize } from 'utils/Reserbus';
import AdjacentBadge from 'images/badges/adjacent-seat.svg';
import AdjacentToggle from 'ui/molecules/AdjacentToggle';
import SeatsAlert from './SeatsAlert';

/**
 * Renders the price preview component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.tripPricing - The trip pricing.
 * @param {Array} props.seatsSelected - The selected seats.
 * @param {string} props.ctaText - The call to action text.
 * @param {boolean} props.showWomanSwitch
 * @param {Function} props.onWomanSwitchChange
 * @param {boolean} props.womanSwitchValue
 * @param {boolean} props.womanPoliciesError
 * @param {boolean} props.showPetFriendly
 * @param {Function} props.onContinue - The on continue function.
 * @param {Function} props.onSelectAdjacent - The on select adjacent function.
 * @param {boolean} props.adjacentSwitchValue - The adjacent switch value.
 * @param {boolean} props.showAdjacentCard - The flag to show the adjacent card.
 * @returns {JSX.Element} The rendered component.
 */
function SeatsPreviewPricing({
  tripPricing,
  seatsSelected,
  ctaText,
  showWomanSwitch,
  onWomanSwitchChange,
  womanSwitchValue,
  womanPoliciesError,
  showPetFriendly,
  onContinue,
  onSelectAdjacent,
  adjacentSwitchValue,
  showAdjacentCard,
}) {
  const passengersCount = seatsSelected.filter((seat) => !seat.isPickedAsAdjacent).length;
  const adjacentSeats = seatsSelected.filter((seat) => seat.isPickedAsAdjacent);
  const pricingPreview = getTripPricing({ passengersCount, pricing: tripPricing });
  const {
    total = 0,
    totalBeforeDiscount = 0,
    discount = 0,
    totalPercentDiscount,
    discountType,
    luggage = 0,
    taxes = 0,
    networks = 0,
    insurance = 0,
  } = pricingPreview;

  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  const { t } = useTranslation();
  const { brand } = useWhitelabelEnvs();
  const { VALID_CATEGORIES, PRICING_BEFORE_CHECKOUT } = useWhitelabelFeatures();
  const isDesktop = isDesktopSize();

  const validCategories =
    VALID_CATEGORIES?.filter((category) => category !== 'general' && category !== 'pet_friendly') ||
    [];

  const categories = validCategories
    .slice(0, 3)
    .map((category) => t(`passengers:type.${category}`, { context: 'simple' }))
    .join(', ');

  const firstText = `${t('general:the_discount_for')} `;
  const firstTextFormatted = !isDesktop
    ? `${firstText.charAt(0).toLocaleLowerCase()}${firstText.slice(1)}`
    : firstText;

  const validDiscount = discountTypes[discountType];
  const discountIcon = validDiscount?.icon?.[brand];

  const passengersLabel = `${passengersCount} ${t(
    passengersCount !== 1 ? 'passengers:label.passengers' : 'passengers:label.passenger',
  )}`;
  const hasDiscount = Boolean(total && discount);
  const hasSpecialLabelDiscount = Boolean(discountIcon && validDiscount?.inLabel);
  const discountLabel = hasSpecialLabelDiscount
    ? t(`trips:${validDiscount?.inLabel}`)
    : t('trips:label.save');
  const discountText = `${discountLabel} ${totalPercentDiscount}`;

  const categoriesComponent = (
    <When condition={total && categories.length}>
      <Text size="XS" textAlign="start">
        {!isDesktop && (
          <Text size="XS" elementType="span" weight="semibold">
            {`${t('general:approx_price')}, `}
          </Text>
        )}
        {firstTextFormatted}
        <Text size="XS" color="primary" elementType="span">
          {`${categories}, etc. `}
        </Text>{' '}
        {` ${t('general:you_will_added_next')}`}
      </Text>
    </When>
  );

  const hasAdjacentSeats = Boolean(adjacentSeats.length);

  const adjacentsTotal =
    hasAdjacentSeats && adjacentSeats[0].adjacentSeats.price * adjacentSeats.length;
  const totalToUse = hasAdjacentSeats ? total + adjacentsTotal : total;

  const totalComponent = Boolean(totalToUse) && (
    <Currency
      price={totalToUse}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
      decimals={getCurrencyDecimals()}
      size="L"
      weight="bold"
    />
  );

  const womanSwitchComponent = showWomanSwitch && (
    <div data-testid="womanCheck">
      <ToggleLabel
        onChange={onWomanSwitchChange}
        value={womanSwitchValue}
        label={t('seats:confirm_woman')}
        iconType="woman"
        error={womanPoliciesError}
        fillBackground
      />
    </div>
  );

  const adjacentSwitchComponent = showAdjacentCard && (
    <AdjacentToggle onSelect={onSelectAdjacent} value={adjacentSwitchValue} />
  );

  const detailsTooltipComponent = Boolean(total && PRICING_BEFORE_CHECKOUT) && (
    <PricingTooltipDetails
      luggage={luggage}
      networks={networks}
      taxes={taxes}
      insurance={insurance}
    />
  );

  const component = (
    <>
      <Visibility type="hideForMedium">
        <Text weight="bold" size="S">
          {t('general:approx_price')}
        </Text>
      </Visibility>

      <Visibility type="hideForMedium">
        <Spacing size="XS" vertical>
          {total ? (
            <>
              <PricingRow label={passengersLabel} price={totalBeforeDiscount} isSpaceBetween />
              {hasAdjacentSeats && (
                <PricingRow
                  label={t('seats:adjacent_seat.label')}
                  price={adjacentsTotal}
                  icon={AdjacentBadge}
                  isSpaceBetween
                />
              )}

              {hasDiscount ? (
                <PricingRow
                  label={discountText}
                  price={discount}
                  hasDiscount
                  isSpaceBetween
                  icon={discountIcon}
                />
              ) : null}
            </>
          ) : (
            <>
              <Text textAlign="center" color="grayMedium">
                {ctaText}
              </Text>
              <br />
            </>
          )}
          <Spacing size="XXS" vertical alignItems="flex-end">
            {totalComponent}
            {womanSwitchComponent}
            {adjacentSwitchComponent}
            {detailsTooltipComponent}
            {categoriesComponent}
          </Spacing>
        </Spacing>
      </Visibility>
      {(showWomanSwitch || showAdjacentCard) && (
        <Visibility type="showForMobileOnly">
          <div className="switch-mobile">
            {womanSwitchComponent}
            {adjacentSwitchComponent}
          </div>
        </Visibility>
      )}

      <Visibility type="hideForMedium">
        <SeatsAlert padding="S" titleSize="S" titleMobileSize="M" />
      </Visibility>
      <SeatsButtonWrapper
        ctaText={ctaText}
        onClick={onContinue}
        showContent={Boolean(seatsSelected.length || !isDesktop)}
      >
        <Visibility type="hideForLargeOnly">
          <Spacing size="S" vertical>
            <Visibility type="hideForLargeOnly">
              <SeatsAlert padding="S" titleSize="S" titleMobileSize="M" />
            </Visibility>
            {detailsTooltipComponent}
            <Spacing alignItems="center" justifyContent="space-between">
              {categoriesComponent}
              {totalComponent}
            </Spacing>
          </Spacing>
        </Visibility>

        <Visibility type="hideForMedium">
          <Spacing justifyContent="center" alignItems="center">
            <When condition={seatsSelected.length}>
              <Text size="S" color="grayMedium" whiteSpace="nowrap">
                {t('seats:pet_counter:title')}
              </Text>
              <Gap size="S">
                {seatsSelected.map((seat) => (
                  <NewSeat
                    key={seat.number}
                    number={seat.number}
                    category={seat.category}
                    type="selected"
                    isSmall
                    newDesign
                    showPetFriendly={showPetFriendly}
                  />
                ))}
              </Gap>
            </When>
          </Spacing>
        </Visibility>
      </SeatsButtonWrapper>
    </>
  );

  return isDesktop ? (
    <div className={`seats-preview-pricing ${isFlat ? 'seats-preview-pricing-flat' : ''}`}>
      {component}
    </div>
  ) : (
    component
  );
}

SeatsPreviewPricing.propTypes = {
  tripPricing: PropTypes.object.isRequired,
  seatsSelected: PropTypes.array,
  ctaText: PropTypes.string,
  showWomanSwitch: PropTypes.bool,
  onWomanSwitchChange: PropTypes.func,
  womanSwitchValue: PropTypes.bool,
  womanPoliciesError: PropTypes.bool,
  showPetFriendly: PropTypes.bool,
  onContinue: PropTypes.func,
  onSelectAdjacent: PropTypes.func,
  adjacentSwitchValue: PropTypes.bool,
  showAdjacentCard: PropTypes.bool,
};

SeatsPreviewPricing.defaultProps = {
  seatsSelected: [],
  ctaText: '',
  onContinue: () => {},
};

export default SeatsPreviewPricing;
