import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PaymentOptions from 'components/purchase/PaymentOptions';
import { getCardBrand } from 'utils/OpenPay';
import { checkoutFunnelStep } from 'utils/Reserbus';
import Loading from 'components/Loading';
import formErrorsTracker from 'metrics/user-analytics/formErrorsTracker';
import usePrevious from 'hooks/usePrevious';
import useCybersourceKeys from 'hooks/useCybersourceKeys';
import useReactNativeMessage from '../../../hooks/useReactNativeMessage';

const MobilePayment = ({
  errors,
  clearErrors,
  getPurchase,
  fetchingPayment,
  purchase,
  onSubmitForm,
  resetPaymentCardError,
  cardErrorOccured,
  transitionTo,
  paymentId,
  ...props
}) => {
  const { availablePayments, selectedPaymentOption, openTicket, token, total, isUpdating, loaded } =
    purchase;

  const [paymentType, setPaymentType] = useState('credit_card');
  const [isLoading, setIsLoading] = useState(isUpdating || fetchingPayment || !loaded);
  const prevFetchingPayment = usePrevious(fetchingPayment);
  const isCreditCardAvailable = availablePayments.some((type) => type === 'credit_card');
  const RNMessage = useReactNativeMessage();
  useCybersourceKeys();

  // eslint-disable-next-line no-unused-vars
  const paymentAttempt = () => {
    if (selectedPaymentOption === 'credit_card') {
      onSubmitForm('card');
    }
  };

  // On message received from mobile
  const onMessage = (message) => {
    const { action } = message.data;
    switch (action) {
      case 'checkPayment':
        paymentAttempt();
        break;
      default:
        break;
    }
  };

  const loadListener = () => {
    RNMessage.listen(onMessage);
  };

  const sendHeightMessage = useCallback(() => {
    const height = document.querySelector('div.l-container').clientHeight;
    RNMessage.post({ height });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendErrorMessage = useCallback(
    (error) => {
      RNMessage.post({ error });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isLoading) {
      sendHeightMessage();
    }
    RNMessage.post({ loading: isLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, sendHeightMessage]);

  useEffect(() => {
    setIsLoading(isUpdating || fetchingPayment || !loaded);
  }, [loaded, isUpdating, fetchingPayment]);

  useEffect(() => {
    if (prevFetchingPayment && !fetchingPayment) {
      if (paymentId) {
        // go to payment processor after fetching the payment
        transitionTo(`/payment/${purchase.token}/${paymentId}?origin=mobile-app`);
      } else {
        sendErrorMessage('Payment error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingPayment]);

  // get the purchase on component mount if not loaded
  useEffect(() => {
    if (!loaded) getPurchase();

    if (typeof resetPaymentCardError === 'function') {
      resetPaymentCardError();
    }

    loadListener();

    return () => {
      const { resetInstallmentsPlan } = props;
      resetInstallmentsPlan();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sending message to mobile app to indicate that credit card method is not available
  useEffect(() => {
    if (loaded && !isCreditCardAvailable) {
      sendErrorMessage('Credit card not available');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditCardAvailable, loaded]);

  const cardPaymentAttempt = () => {
    const { onCardPaymentAttempt, cardValues } = props;

    onCardPaymentAttempt(token, cardValues, total);
  };

  const handleFormErrors = (fields) => {
    const {
      cardValues: { cardNumber },
    } = props;

    const paymentInfo = {
      paymentType,
    };

    if (cardNumber) {
      paymentInfo.cardBrand = getCardBrand(cardNumber);
    }

    formErrorsTracker(checkoutFunnelStep(purchase.departs, purchase.returns), fields, paymentInfo);
    RNMessage.post({ formErrors: fields });
  };

  const handleChangePaymentType = (paymentType) => {
    const { onSelectPaymentOption } = props;

    setPaymentType(paymentType);
    onSelectPaymentOption(paymentType);
  };

  useEffect(() => {
    if (cardErrorOccured) {
      sendErrorMessage('Credit card payment error');
    }
  }, [cardErrorOccured, sendErrorMessage]);

  // Avoid show error message alert on window
  useEffect(() => {
    if (errors && errors.code !== 0) {
      sendErrorMessage(errors.message);
      clearErrors();
    }
  }, [clearErrors, errors, sendErrorMessage]);

  if (isLoading || !loaded || !isCreditCardAvailable) {
    return <Loading />;
  }

  return (
    <div className="l-mobile-purchase">
      <div className="l-container">
        <PaymentOptions
          availablePayments={['credit_card']}
          submitCard={cardPaymentAttempt}
          submitCardFail={handleFormErrors}
          selectedOption={selectedPaymentOption}
          onPaymentTypeChange={handleChangePaymentType}
          isOpenTicket={openTicket}
          cardErrorOccured={cardErrorOccured}
          hidePurchaserForm
        />
      </div>
    </div>
  );
};

MobilePayment.propTypes = {
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  getPurchase: PropTypes.func.isRequired,
  purchase: PropTypes.shape({
    isUpdating: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    loaded: PropTypes.bool.isRequired,
    departs: PropTypes.object.isRequired,
    returns: PropTypes.object.isRequired,
    availablePayments: PropTypes.array,
    selectedPaymentOption: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    openTicket: PropTypes.bool,
  }),
  paymentId: PropTypes.number,
  fetchingPayment: PropTypes.bool.isRequired,
  cardValues: PropTypes.object.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onCardPaymentAttempt: PropTypes.func.isRequired,
  onSelectPaymentOption: PropTypes.func.isRequired,
  resetInstallmentsPlan: PropTypes.func.isRequired,
  insuranceMessage: PropTypes.string,
  cardErrorOccured: PropTypes.bool,
  transitionTo: PropTypes.func.isRequired,
  resetPaymentCardError: PropTypes.func,
};

export default MobilePayment;
