/* eslint-disable react/jsx-no-bind */
import React from 'react';
import useUser from 'hooks/useUser';
import { Dropdown, FlatButton, InfoCard, Spacing, Text, Visibility } from '@reservamos/elements';
import { formatCurrency } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import noUserPictureIcon from 'images/brands/reservamos/no-user-picture.svg';
import CoinSvg from 'images/brands/reservamos/wallet/coin.svg';
import 'styles/components/UserDropwdown';

function UserDropwdown() {
  const { user, isAuthenticated, logout, loginWithRedirect, profileUrl } = useUser();
  const { t } = useTranslation('user');

  function handleLogin() {
    loginWithRedirect({
      appState: { returnTo: `${window.location.pathname}${window.location.search}` },
    });
  }

  function myAccountRedirect() {
    window.open(profileUrl, '_self');
  }

  return (
    <Dropdown
      trigger={
        <Spacing size="XS" alignItems="center">
          <Visibility type="hideForMobileOnly">
            <Text size="S" weight="semibold">
              {isAuthenticated ? user.givenName || user.email || '' : t('buttons.my_account')}
            </Text>
          </Visibility>

          <img
            src={user.givenName ? user.picture : noUserPictureIcon}
            style={{ borderRadius: '100%', height: '25px' }}
          />
        </Spacing>
      }
    >
      <InfoCard
        icon={CoinSvg}
        title={t('info_card_title', {
          context: isAuthenticated ? 'auth' : 'unauth',
          name: user.givenName,
        })}
      >
        <Text size="S">
          {t('info_card_content', { context: isAuthenticated ? 'auth' : 'unauth' })}
        </Text>
      </InfoCard>
      {isAuthenticated && (
        <div className="wallet-padding">
          <Spacing size="XS">
            <Text>{t('wallet_details')}</Text>
            <Text weight="semibold" color="accent">
              {formatCurrency(user.walletBalance)}
            </Text>
          </Spacing>
        </div>
      )}
      <div className="dropdown-buttons">
        <FlatButton
          onClick={isAuthenticated ? myAccountRedirect : handleLogin}
          textDecorationLine="none"
          size="S"
          type="grayStrong"
        >
          {t('buttons.first', { context: isAuthenticated ? 'auth' : 'unauth' })}
        </FlatButton>

        <FlatButton
          onClick={isAuthenticated ? logout : handleLogin}
          textDecorationLine="none"
          size="S"
        >
          {t('buttons.second', { context: isAuthenticated ? 'auth' : 'unauth' })}
        </FlatButton>
      </div>
    </Dropdown>
  );
}

export default UserDropwdown;
