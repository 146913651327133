import React, { useState } from 'react';
import { Button, Icon, Input, Spacing, Text, Tooltip } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import tokenReference from 'images/brands/reservamos/token-reference.jpg';

/**
 * InvoicingForm component
 *
 * This component renders a form for invoicing, allowing the user to input a token.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
function InvoicingForm() {
  const [token, setToken] = useState('');
  const { t } = useTranslation('invoicing');

  /**
   * Handles the change event for the token input field.
   *
   * @param {Object} event - The event object
   */
  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  return (
    <form>
      <div className="invoice-form">
        <Spacing vertical>
          <div className="invoice-tooltip">
            <Input
              id="token"
              name="token"
              type="text"
              value={token}
              onChange={handleTokenChange}
              label={t('form.placeholder')}
            />

            <Tooltip
              type="left"
              variant="light"
              widthBox={286}
              customContent={
                <div>
                  <Text size="XS" weight="semibold">
                    {t('form.tooltip')}
                  </Text>
                  <img className="invoice-img-tooltip" src={tokenReference} />
                </div>
              }
            >
              <Icon size="L" type="badgeQuestion" />
            </Tooltip>
          </div>

          <Button
            fullWidth
            variant="accent"
            text={t('form.button')}
            minHeight="45px"
            buttonType="submit"
          />
        </Spacing>
      </div>
    </form>
  );
}

export default InvoicingForm;
