import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider as Auth0ProviderOg } from '@auth0/auth0-react';
import UserProvider from './UserProvider';

function Auth0Provider({ config, children }) {
  if (!config.enabled) return null;

  const onRedirectCallback = (appState) => {
    if (appState && appState.returnTo) {
      window.location.href = appState.returnTo;
    }
  };

  return (
    <Auth0ProviderOg
      domain={config.issuerBaseUrl}
      clientId={config.clientId}
      authorizationParams={{ redirect_uri: window.location.origin, audience: config.audience }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <UserProvider config={config}>{children}</UserProvider>
    </Auth0ProviderOg>
  );
}

Auth0Provider.propTypes = {
  config: PropTypes.shape({
    issuerBaseUrl: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    audience: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Auth0Provider;
