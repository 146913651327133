import React from 'react';
import store from '@/store';
import queryString from 'query-string';
import { connect, useSelector } from 'react-redux';
import { purchaseOnHoldTracker, purchaseTracker } from 'metrics/user-analytics/purchase';
import { getCardBrand } from 'utils/OpenPay';
import { checkoutFunnelStep } from 'utils/Reserbus';
import { getPurchase, pollPurchaseComplete } from '@/actions/purchase';
import { getInstance } from 'utils/taplytics';
import { setError } from '../../../actions';
import BasePurchaseComplete from './PurchaseComplete';
import PurchaseCompleteFlat from './flat/PurchaseComplete';
import { isHybridTrip } from '../../../utils/wayIsOpenTicket';

const mapStateToProps = ({ purchase, payment, siemprePlus }) => {
  const { user } = siemprePlus.toJS();
  const loaded = purchase.get('loaded');

  const jsPurchase = loaded && {
    email: purchase.get('email'),
    phone: purchase.get('phone'),
    phoneCode: purchase.get('phoneCode'),
    token: purchase.get('token'),
    total: purchase.get('total'),
    taxes: purchase.get('taxes'),
    markup: purchase.get('markup'),
    insuranceAmount: purchase.get('insuranceAmount'),
    passengerSelection: purchase.get('passengerSelection').toJS(),
    purchaserNationality: purchase.get('purchaserNationality'),
    departs: purchase.get('departs').toJS(),
    returns: purchase.get('returns').toJS(),
    roundTrip: purchase.get('roundTrip'),
    walletPointsUsed: purchase.get('walletTotalPointsUsed') || purchase.get('walletPointsUsed'),
    walletBalanceUsed: purchase.get('walletBalanceUsed'),
    walletType: purchase.get('walletType'),
    walletAccrualPoints: purchase.get('walletAccrualPoints'),
  };

  return {
    loaded,
    isFetching: purchase.get('isFetching') || Boolean(payment.get('isFetching')),
    purchase: jsPurchase,
    purchaseStatus: purchase.get('state') || payment.get('purchaseState'),
    paymentStatus: payment.get('status'),
    paymentType: payment.get('paymentType') || 'credit_card',
    paymentEngine: payment.get('paymentEngine'),
    paymentProvider: payment.get('paymentProvider'),
    transferPaymentState: payment.get('kushkiProcessorState'),
    isOpenTicket: Boolean(purchase.get('openTicket')),
    email: purchase.get('email'),
    departsTicketPdfUrl: purchase.getIn(['departs', 'fragments', 0, 'ticketPdfUrl']),
    departsTransporterKey: purchase.getIn(['departs', 'fragments', 0, 'transporterKey']),
    returnTicketPdfUrl: purchase.getIn(['returns', 'fragments', 0, 'ticketPdfUrl']),
    returnsTransporterKey: purchase.getIn(['returns', 'fragments', 0, 'transporterKey']),
    user,
    isHybridTrip: isHybridTrip(purchase.get('departs').toJS(), purchase.get('returns').toJS()),
  };
};

const mapDispatchToProps = {
  getPurchase,
  pollPurchaseComplete,
  setError,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { trackPurchase = false } = queryString.parse(ownProps.location.search, {
    parseBooleans: true,
  });
  const { token } = ownProps.match.params;
  const { env } = store.getState().whitelabelConfig;
  const { purchase, paymentType, paymentEngine } = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    token,
    trackPurchase,
    trackPurchaseHandler: () => {
      const funnelStep = checkoutFunnelStep(purchase.departs, purchase.returns);
      // TODO: find a way to get the card number
      const cardBrand = getCardBrand();

      purchaseTracker({ purchase, funnelStep, cardBrand, paymentEngine, paymentType });
      const search = queryString.parse(window.location.search);
      delete search.trackPurchase;

      const url = `/purchase/${token}/complete?${queryString.stringify(search)}`;

      if (stateProps.purchaseStatus === 'completed') {
        if (env.taplytics.enabled) {
          const taplytics = getInstance();
          taplytics.track('purchase complete', { token });
        }

        ownProps.history.replace(url);
      } else {
        purchaseOnHoldTracker(token);
        ownProps.history.replace(url);
      }
    },
  };
};

const PurchaseComplete = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const FLAT_STYLE = features.FUNNEL_STYLE === 'FLAT';
  const PurchaseCompleteToRender = FLAT_STYLE ? PurchaseCompleteFlat : BasePurchaseComplete;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PurchaseCompleteToRender {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PurchaseComplete);
