/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  Checkbox,
  Currency,
  Icon,
  Spacing,
  Text,
  TextCollapse,
} from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import basicIcon from 'images/brands-unique/reservamos/sl-basic.svg';
import maxIcon from 'images/brands-unique/reservamos/sl-max.svg';
import 'styles/components/ServiceLevels/ServiceLevelOption.scss';

const serviceLevelMaxBenefits = ['1', '2', '3'];
const serviceLevelBasicBenefits = ['1', '2', '3'];

/**
 * ServiceLevelOption component
 *
 * @param {Object} props - Component props
 * @param {string} props.coverage - The coverage type ('basic' or 'max')
 * @param {boolean} [props.isChecked=false] - Flag indicating if the option is checked
 * @param {boolean} [props.isUpdating=false] - Flag indicating if the option is being updated
 * @param {Function} [props.handleChange=null] - Function to handle change events
 * @param {Array} [props.serviceLevels=null] - List of available service levels
 * @returns {JSX.Element|null} The rendered component or null if coverage is not provided
 */
const ServiceLevelOption = ({ coverage, isChecked, isUpdating, handleChange, serviceLevels }) => {
  const isBasicCoverage = coverage === 'basic';
  const [serviceLevelsPrices, setServiceLevelsPrices] = useState({ basic: 0, other: 0 });
  const { t } = useTranslation('purchase');

  useEffect(() => {
    const basic = serviceLevels?.find((sl) => sl.name === 'basic').price;
    const other = serviceLevels?.find((sl) => sl.name !== 'basic').price;
    setServiceLevelsPrices({ basic, other });
  }, [serviceLevels]);

  if (!coverage) return null;

  const serviceLevelsBenefits = {
    basic: serviceLevelBasicBenefits.map((number) => {
      return t('service-levels.basic-benefits', {
        context: number,
      });
    }),
    max: serviceLevelMaxBenefits.map((number) => {
      return t('service-levels.max-benefits', {
        context: number,
      });
    }),
  };

  return (
    <div className="service-level">
      <Card
        isActive={isChecked}
        padding="M"
        hasBorder={!isBasicCoverage}
        hasShadow={!isBasicCoverage}
      >
        <Spacing justifyContent="space-between">
          <Spacing size="XS" alignItems="center">
            {handleChange && (
              <Checkbox
                checked={isChecked}
                id={`service-level-checkbox-${coverage}`}
                isLoading={isUpdating}
                onChange={handleChange}
                name={coverage}
              />
            )}
            <label htmlFor={`service-level-checkbox-${coverage}`}>
              <Spacing size="XS" alignItems="center">
                <Icon size="M" type={coverage === 'basic' ? basicIcon : maxIcon} />

                <Text
                  color="grayStrong"
                  weight="bold"
                  mobileSize="S"
                  textAlign="left"
                  size={isBasicCoverage ? 'S' : 'M'}
                >
                  {t(`service-levels.${coverage}`, {
                    context: 'title',
                  })}
                </Text>
              </Spacing>
            </label>
          </Spacing>

          <Spacing size="XS" alignItems="center">
            {isBasicCoverage ? (
              <Text color="accent" weight="bold">
                {t('service-levels.basic', { context: 'pricing' })}
              </Text>
            ) : (
              <>
                <Text color="accent" weight="bold" size="XL">
                  +
                </Text>
                <Currency
                  mobileSize="M"
                  size="L"
                  weight="bold"
                  price={serviceLevelsPrices?.other - serviceLevelsPrices?.basic}
                  currency="mxn"
                  color="accent"
                />
              </>
            )}
          </Spacing>
        </Spacing>

        <div className="description">
          <Text color="grayMedium" size="S" weight="semibold" elementType="span">
            {t(`service-levels.${coverage}`, { context: 'description' })}
          </Text>
        </div>

        {coverage === 'max' && (
          <div className="badge-container">
            <Badge type="info" roundCorners>
              <Text elementType="span" size="XS" weight="semibold" color="white">
                {t('service-levels.favorite')}
              </Text>
            </Badge>
          </div>
        )}

        <TextCollapse
          margin="S"
          triggerTextCollapsed={t('service-levels.more')}
          triggerTextExpanded={t('service-levels.less')}
          triggerOptions={{
            size: 'S',
          }}
        >
          <Spacing vertical size="S">
            {serviceLevelsBenefits[coverage].map((benefit) => (
              <Spacing size="XS" alignItems="center" key={benefit}>
                <Icon size="S" type="checkmarkGreen" />
                <Text color="grayMedium" size="S">
                  {benefit}
                </Text>
              </Spacing>
            ))}
          </Spacing>
        </TextCollapse>
      </Card>
    </div>
  );
};

ServiceLevelOption.propTypes = {
  coverage: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isUpdating: PropTypes.bool,
  handleChange: PropTypes.func,
  serviceLevels: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

ServiceLevelOption.defaultProps = {
  isChecked: false,
  isUpdating: false,
  handleChange: null,
  serviceLevels: null,
};

export default ServiceLevelOption;
