import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PassengerTypeRadio from './PassengerTypeRadio';

const AvailableCategory = ({ name, type, isDisabled }) => {
  const { t } = useTranslation('seats');
  const { brand } = useWhitelabelEnvs();
  return (
    <Field
      name={name}
      type="radio"
      value={type}
      component={PassengerTypeRadio}
      isDisabled={isDisabled}
    >
      {t(`passengers:type.${type}`, { context: brand })}
    </Field>
  );
};

AvailableCategory.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

const AvailableCategoriesList = ({ categories, name, isDisabled }) => {
  return categories.map(({ type }) => (
    <AvailableCategory key={type} name={name} type={type} isDisabled={isDisabled} />
  ));
};

AvailableCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default AvailableCategoriesList;
