import React from 'react';
import PropTypes from 'prop-types';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import TimeLineItem from './TimeLineItem';
import makeKey from './makeKey';
import 'styles/components/TripItinerary/ItineraryDetails';

const propTypes = {
  route: PropTypes.object,
  brand: PropTypes.string,
};

const TimeLine = ({ route: { route, originDetails, destinationDetails } }) => {
  const { brand } = useWhitelabelEnvs();
  const finalRoute =
    brand !== 'brasilia'
      ? route
      : route
          .map((timeSegment) => {
            if (timeSegment.type === 'stop') {
              return [
                {
                  type: 'end',
                  time: timeSegment.time[0].time,
                  title: timeSegment.title,
                  description: `Llega a: ${timeSegment.title}`,
                },
                {
                  type: 'departure',
                  time: timeSegment.time[1].time,
                  title: timeSegment.title,
                  description: `Sale de: ${timeSegment.title}`,
                },
              ];
            }
            return timeSegment;
          })
          .flat();

  let index = 1;
  const timeLineItems = finalRoute?.map((timeSegment) => {
    const key = makeKey(timeSegment);
    if (timeSegment.type === 'departure' && brand === 'brasilia') {
      timeSegment.index = index;
      index += 1;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <TimeLineItem
        {...timeSegment}
        key={key}
        componentkey={key}
        brand={brand}
        originDetails={originDetails}
        destinationDetails={destinationDetails}
      />
    );
  });
  return <div>{timeLineItems}</div>;
};

TimeLine.propTypes = propTypes;

export default TimeLine;
