import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import { Form, FieldArray } from 'redux-form';
import { passengersTracker, exchangePassengersTracker } from 'metrics/user-analytics/purchase';
import { identifyUser } from 'user-analytics';
import OneSignal from 'react-onesignal';
import Loading from 'components/Loading';
import CheckoutNavigation from 'components/purchase/CheckoutNavigation';
import ModalTripInsurance from 'components/purchase/ModalContent';
import { Checkbox, Spacing, Visibility } from '@reservamos/elements';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import PurchaserFields from '../PurchaserForm/PurchaserFields';
import DynamicPassengersForm from './DynamicPassengersForm';
import StaticPassengersForm from './StaticPassengersForm';
import PassengerPolicies from '../PassengerPolicies';
import OpenTicketMessage from '../OpenTicketMessage';
import wayIsOpenTicket, { isHybridTrip } from '../../../utils/wayIsOpenTicket';
import wayAllowsSeatSelection from '../../../utils/purchase/wayAllowsSeatSelection';
import InsuranceDialog from '../InsuranceDialog';
import LoyaltyButton from '../../LoyaltyButton';
import PassengersDisclaimer from './PassengersDisclaimer';
import InsuranceAncillarySwitch from '../InsuranceAncillarySwitch';
import 'styles/components/purchase/Passengers';
import OpenTicketNotice from '../OpenTicketNotice';
import ReturnCTA from '../Purchase/ReturnCTA';
import DotersBanner from '../../../ui/atoms/DotersBanner';
import MainButton from '../../../ui/atoms/MainButton';
import FlatFareSwitch from '../../Doters/FlatFare/FlatFareSwitch';
import LoyaltySelection from '../../../ui/molecules/LoyaltySelection';
import LoyaltyProgramsContext from '../../../loyalty/context/LoyaltyProgramsContext';

const fragment = PropTypes.shape({
  id: PropTypes.string.isRequired,
  transportType: PropTypes.string.isRequired,
});

const way = PropTypes.shape({
  fragments: PropTypes.arrayOf(fragment),
  selectedSeats: PropTypes.any,
});

const propTypes = {
  purchase: PropTypes.shape({
    isExchange: PropTypes.bool.isRequired,
    allowsSeatSelection: PropTypes.bool.isRequired,
    departs: way.isRequired,
    hasSelectedSeats: PropTypes.bool.isRequired,
    isFetchingDetails: PropTypes.bool.isRequired,
    isLockingTickets: PropTypes.bool.isRequired,
    roundTrip: PropTypes.bool.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    hasTickets: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    passengerSelection: PropTypes.object,
    passengers: PropTypes.array,
    returns: way,
    savingPassengers: PropTypes.bool.isRequired,
    qualifiesForInsurance: PropTypes.bool.isRequired,
    updatingInsurance: PropTypes.bool.isRequired,
    openTicket: PropTypes.bool.isRequired,
    insuranceDurationDays: PropTypes.number,
    insuranceOutgoingUnitAmount: PropTypes.number,
    insuranceIncomingUnitAmount: PropTypes.number,
    insurancesChecked: PropTypes.bool,
    ticketsErrorType: PropTypes.string,
    wantsInsurance: PropTypes.bool.isRequired,
    isUpdatingFlatFare: PropTypes.bool.isRequired,
    walletType: PropTypes.string,
    isUpdatingWalletType: PropTypes.bool,
    isUpdating: PropTypes.bool,
    preSelectedSeats: PropTypes.shape({
      departure: PropTypes.array,
      return: PropTypes.array,
    }),
  }),
  passengersForm: PropTypes.object.isRequired,
  purchaser: PropTypes.object.isRequired,
  busCategories: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  savePassengers: PropTypes.func.isRequired,
  autofillFirstPassenger: PropTypes.func.isRequired,
  autofillInsurances: PropTypes.func.isRequired,
  setInsurancesChecked: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  returnTo: PropTypes.func.isRequired,
  clearTicketsErrors: PropTypes.func.isRequired,
  deletePassenger: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  insuranceMessage: PropTypes.string,
  insuranceAmount: PropTypes.string,
  insuranceAmountIsUnitPrice: PropTypes.bool,
  onToggleWantsInsurance: PropTypes.func.isRequired,
  isExchange: PropTypes.bool,
  features: PropTypes.object.isRequired,
  env: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  user: PropTypes.object,
  showPetFriendlySeatsWarn: PropTypes.func,
  showAcceptTermsWarning: PropTypes.func,
  petFriendlyQuantityIsValid: PropTypes.func,
  isLogged: PropTypes.bool,
  isAccountThePassenger: PropTypes.bool,
  needsLoyaltySelector: PropTypes.bool,
  isUpdatingFlatFare: PropTypes.bool,
  clearError: PropTypes.func,
};

class Passengers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autofillEnabled: false,
      insuranceDialogVisible: false,
      acceptedTerms: true,
    };

    this.onSubmitPassengers = this.onSubmitPassengers.bind(this);
    this.togglePassengerAutofill = this.togglePassengerAutofill.bind(this);
    this.passengersInsurancesMissing = this.passengersInsurancesMissing.bind(this);
    this.selectAllInsurances = this.selectAllInsurances.bind(this);
    this.needsInsuranceReminder = this.needsInsuranceReminder.bind(this);
    this.toggleWantsInsurance = this.toggleWantsInsurance.bind(this);
    this.insuranceReminderGFA = this.insuranceReminderGFA.bind(this);
    this.insuranceReminderDefault = this.insuranceReminderDefault.bind(this);
    this.activateWantsInsurance = this.activateWantsInsurance.bind(this);
    this.toggleAcceptTerms = this.toggleAcceptTerms.bind(this);
  }

  componentDidMount() {
    const { clearError } = this.props;
    // Redirect to previous step if tickets are not set
    // setTimeout to queue as last, so is executed after loyalty (prev siempre-plus) user is set
    clearError();
    setTimeout(() => {
      this.redirectIfAppropriate();
    }, 1);
  }

  componentWillReceiveProps(nextProps) {
    const { purchase, transitionTo } = this.props;
    const { token, isLockingTickets, isExchange } = purchase;

    if (isLockingTickets && !nextProps.purchase.isLockingTickets) {
      // user-analytics "Passengers Created Event"

      if (isExchange) {
        exchangePassengersTracker(purchase);
      } else {
        passengersTracker(purchase);
      }

      // go to seat selection
      transitionTo(`/purchase/${token}/checkout`);
    }
  }

  shouldComponentUpdate(nextProps) {
    // Don't update if purchase is not in attempt state since it
    // will get redirected to the appropriate route
    return nextProps.purchase.state === 'attempt';
  }

  componentDidUpdate(prevProps) {
    const { autofillFirstPassenger, clearTicketsErrors, returnTo, purchaser, submit, purchase } =
      this.props;
    const { insurancesChecked, ticketsErrorType, token } = purchase;
    const {
      purchaserFirstName,
      purchaserLastName,
      email,
      purchaserSecondLastName,
      documentType,
      documentId,
    } = purchaser;
    const { autofillEnabled } = this.state;
    const purchaserChanged =
      prevProps.purchaser.purchaserFirstName !== purchaserFirstName ||
      prevProps.purchaser.purchaserLastName !== purchaserLastName ||
      prevProps.purchaser.purchaserSecondLastName !== purchaserSecondLastName ||
      prevProps.purchaser.email !== email;

    if (autofillEnabled && purchaserChanged) {
      autofillFirstPassenger(
        purchaserFirstName,
        purchaserLastName,
        purchaserSecondLastName,
        documentType,
        documentId,
        email,
      );
    }

    if (ticketsErrorType) {
      if (ticketsErrorType === 'occupied') returnTo(`/purchase/${token}/seats/departure`);
      clearTicketsErrors();
    }

    if (!prevProps.purchase.insurancesChecked && insurancesChecked) {
      submit();
    }
  }

  onSubmitPassengers(fields) {
    const {
      purchase,
      savePassengers,
      user,
      isExchange,
      features,
      env,
      petFriendlyQuantityIsValid,
      showPetFriendlySeatsWarn,
      showAcceptTermsWarning,
    } = this.props;
    const {
      departs,
      returns,
      roundTrip,
      token,
      insuranceIncomingUnitAmount,
      insuranceOutgoingUnitAmount,
    } = purchase;
    const { acceptedTerms } = this.state;
    const trips = {};

    if (features.SHOW_ACCEPT_POLICIES_CHECKBOX && !acceptedTerms) {
      showAcceptTermsWarning();
      return;
    }

    if (!petFriendlyQuantityIsValid()) {
      showPetFriendlySeatsWarn();
      return;
    }

    if (
      ((isExchange && features.CAN_ADD_EXCHANGE_INSURANCE) || !isExchange) &&
      this.needsInsuranceReminder() &&
      (insuranceIncomingUnitAmount > 0 || insuranceOutgoingUnitAmount > 0)
    ) {
      return;
    }

    trips.departs = departs.fragments.map(({ id: fragmentId, transportType }) => ({
      id: fragmentId,
      type: transportType,
      selectedSeats: departs.selectedSeats,
    }));

    if (roundTrip) {
      trips.returns = returns.fragments.map(
        ({ id: returnFragmentId, transportType: returnTransportType }) => ({
          id: returnFragmentId,
          type: returnTransportType,
          selectedSeats: returns.selectedSeats,
        }),
      );
    }

    if (features.PURCHASER_FORM_ON === 'PASSENGERS') {
      const hasOneSignalEnabled = env.oneSignal.enabled;
      const userEmail = fields.email || (fields.passengers[0] ? fields.passengers[0].email : '');
      const phoneCode = getCountryCallingCode(fields.phoneCountry);
      const phoneNumber = parsePhoneNumber(`+${phoneCode}${fields.phone}`);

      if (hasOneSignalEnabled) {
        OneSignal.setSMSNumber(`${phoneNumber.number}`);
        OneSignal.setExternalUserId(phoneNumber.nationalNumber);
        OneSignal.setEmail(userEmail);
        OneSignal.sendTags({ purchase_token: token });
      }
      identifyUser(phoneNumber.nationalNumber, {
        '$first_name': fields.purchaserFirstName,
        '$last_name': fields.purchaserLastName,
        '$name': `${fields.purchaserFirstName} ${fields.purchaserLastName}`,
        '$email': userEmail,
        '$phone': phoneNumber.nationalNumber,
        'User Type': user ? 'Registered' : 'Anonymous',
        '$onesignal_user_id': phoneNumber.nationalNumber,
        ...(user ? { $membership_id: user.spCard || user.dotersId } : {}),
      });
    }

    window.scrollTo(0, 0);
    this.setState({ insuranceDialogVisible: false });
    savePassengers(fields, trips);
  }

  getLoyaltyHolderName() {
    const { purchase } = this.props;
    const { walletType } = purchase;
    if (!walletType) return null;
    const types = {
      siempreplus: 'siempreplus',
      doters: 'doters',
      costapass: 'costapass',
    };

    return types[walletType];
  }

  togglePassengerAutofill() {
    const { autofillEnabled: oldAutofillEnabled } = this.state;
    const { autofillFirstPassenger, purchaser } = this.props;
    const {
      purchaserFirstName,
      purchaserLastName,
      purchaserSecondLastName,
      documentType,
      documentId,
      email,
    } = purchaser;
    const autofillEnabled = !oldAutofillEnabled;

    if (autofillEnabled) {
      autofillFirstPassenger(
        purchaserFirstName,
        purchaserLastName,
        purchaserSecondLastName,
        documentType,
        documentId,
        email,
      );
    } else {
      autofillFirstPassenger('', '', '', '', '');
    }

    this.setState({ autofillEnabled });
  }

  redirectIfAppropriate() {
    const { purchase, returnTo } = this.props;
    const { token, hasTickets, departs, returns, allowsSeatSelection } = purchase;

    const departureValid =
      !wayAllowsSeatSelection(purchase, 'departs') ||
      Object.keys(departs.selectedSeats || {}).length > 0;

    const hasReturn = Boolean(returns.fragments);
    const returnValid =
      !hasReturn ||
      !wayAllowsSeatSelection(purchase, 'returns') ||
      Object.keys(returns.selectedSeats || {}).length > 0;

    const pendingSeatSelection = allowsSeatSelection && (!departureValid || !returnValid);

    /**
     * @todo This is a temporary fix for the case where the user has already selected seats
     * Enterprise already has a better solution for this, we need to implement it
     */
    if (!hasTickets && pendingSeatSelection) {
      returnTo(`/purchase/${token}/seats/departure`);
    }
  }

  insuranceReminderGFA() {
    const { insuranceDialogVisible } = this.state;

    if (this.hasInsurancesToAdd() && !insuranceDialogVisible) {
      this.setState({ insuranceDialogVisible: true });
      return true;
    }
    return false;
  }

  insuranceReminderDefault() {
    const { insuranceDialogVisible } = this.state;
    const { features } = this.props;

    if (
      features.PASSENGERS_INSURANCE_ENABLED &&
      !insuranceDialogVisible &&
      this.passengersInsurancesMissing()
    ) {
      this.setState({ insuranceDialogVisible: true });
      return true;
    }
    return false;
  }

  needsInsuranceReminder() {
    const { env } = this.props;
    const brandReminder = {
      'gfa': this.insuranceReminderGFA,
      'roll-bits': this.insuranceReminderGFA,
      'default': this.insuranceReminderDefault,
    };

    const currentReminder = brandReminder[env.brand] ?? brandReminder.default;

    return currentReminder();
  }

  activateWantsInsurance() {
    const {
      purchase: { token },
      onToggleWantsInsurance,
    } = this.props;

    onToggleWantsInsurance(token, true);
  }

  toggleWantsInsurance(event) {
    const {
      purchase: { token },
      onToggleWantsInsurance,
    } = this.props;

    onToggleWantsInsurance(token, event.target.checked);
  }

  hasInsurancesToAdd() {
    const { purchase, features } = this.props;
    const { qualifiesForInsurance, wantsInsurance } = purchase;

    if (!qualifiesForInsurance) return false;

    if (features.PASSENGERS_INSURANCE_ENABLED) {
      return this.passengersInsurancesMissing();
    }
    return !wantsInsurance;
  }

  passengersInsurancesMissing() {
    const {
      passengersForm: { passengers },
      purchase: { departs, returns, roundTrip },
    } = this.props;

    const departureIsOpen = wayIsOpenTicket(departs) === true;
    if (!departureIsOpen && passengers.values.passengers.some((p) => !p.wantsOutgoingInsurance)) {
      return true;
    }

    const returnsIsOpen = wayIsOpenTicket(returns) === true;
    if (
      roundTrip &&
      !returnsIsOpen &&
      passengers.values.passengers.some((p) => !p.wantsIncomingInsurance)
    ) {
      return true;
    }

    return false;
  }

  selectAllInsurances() {
    const {
      autofillInsurances,
      passengersForm: { passengers },
      setInsurancesChecked,
      purchase: { departs, returns, roundTrip },
      env,
    } = this.props;

    const selectDepartureInsurances = !wayIsOpenTicket(departs);
    const selectReturnsInsurances = roundTrip && !wayIsOpenTicket(returns);
    passengers.values.passengers.forEach((_, passengerIndex) => {
      autofillInsurances(passengerIndex, selectDepartureInsurances, selectReturnsInsurances);
    });

    if (env.brand === 'gfa' || env.brand === 'roll-bits') this.activateWantsInsurance();

    setInsurancesChecked();
  }

  toggleAcceptTerms() {
    this.setState((prevState) => ({ acceptedTerms: !prevState.acceptedTerms }));
  }

  render() {
    const { autofillEnabled, insuranceDialogVisible, acceptedTerms } = this.state;
    const {
      busCategories,
      handleSubmit,
      submit,
      deletePassenger,
      purchase,
      purchase: { wantsInsurance, qualifiesForInsurance, updatingInsurance },
      insuranceMessage,
      insuranceAmount,
      insuranceAmountIsUnitPrice,
      t,
      isExchange,
      purchaser,
      initialValues,
      features,
      user,
      showPetFriendlySeatsWarn,
      isLogged,
      isAccountThePassenger,
      needsLoyaltySelector,
      autofillFirstPassenger,
      isUpdatingFlatFare,
    } = this.props;

    const showPurchaserForm = features.PURCHASER_FORM_ON === 'PASSENGERS';

    const {
      isFetchingDetails,
      savingPassengers,
      isLockingTickets,
      allowsSeatSelection,
      departs,
      returns,
      insuranceDurationDays,
      insuranceOutgoingUnitAmount,
      insuranceIncomingUnitAmount,
      roundTrip,
      isUpdatingWalletType,
      isUpdating,
    } = purchase;

    if (savingPassengers || isLockingTickets) {
      return <Loading loadingText={t('passengers', { context: 'saving' })} />;
    }

    const isDepartureOpen = wayIsOpenTicket(departs);
    const isReturnOpen = wayIsOpenTicket(returns);
    const hasOpenTicket = isDepartureOpen || isReturnOpen;

    const insuranceTitle = t('insurance:Insurance Checkbox Title', { context: 'coverage' });
    const exchangeInsuranceValidation =
      !isExchange || (isExchange && features.CAN_ADD_EXCHANGE_INSURANCE);

    const SHOW_PURCHASER_FORM = features.PURCHASER_FORM_ON === 'PASSENGERS';

    // Checking if it is a hybrid trip to show the not insurance message
    const isHybrid = isHybridTrip(departs, returns);

    const showMessageNotInsuranceOpenTicket = isHybrid && !features.OPENT_TICKET_INSURANCE_ENABLED;
    const showMessageJustAdult = isHybrid && features.JUST_ADULT_OPEN_TICKET;
    const isLoadingNextButton =
      isUpdatingWalletType || updatingInsurance || isUpdating || isUpdatingFlatFare;

    const {
      costaPass: { config: costaPassConfig, userIsLoggedIn: userIsLoggedInWithCostapass } = {},
    } = this.context;
    const mobileLoginSection = (
      <Visibility type="showForMobileOnly">
        <Spacing vertical>
          {features.DOTERS_ENABLED && (
            <div className="login-container">
              <DotersBanner show={Boolean(!isLogged)} />
            </div>
          )}
          {features.COSTAPASS_ENABLED && !userIsLoggedInWithCostapass && (
            <LoyaltyButton
              id="siempre-plus-widget-passengers-button-costapass"
              insideIcon
              extraConfig={costaPassConfig}
            />
          )}
        </Spacing>
      </Visibility>
    );

    return (
      <div className="passengers-container">
        <When condition={needsLoyaltySelector}>
          <LoyaltySelection />
        </When>
        <When condition={!needsLoyaltySelector}>
          <Spacing size="L" vertical>
            {hasOpenTicket && !isHybrid && <OpenTicketMessage isLoggedIn={Boolean(user)} />}
            <ReturnCTA type="card" />

            <Form onSubmit={handleSubmit(this.onSubmitPassengers)}>
              {SHOW_PURCHASER_FORM && (
                <>
                  <div className="passenger-detail-title">
                    <h2>{t('seats:purchaser_info')}</h2>
                  </div>

                  <PurchaserFields
                    initialValues={initialValues}
                    purchaser={purchaser}
                    isExchange={isExchange}
                  />
                </>
              )}

              {insuranceDialogVisible &&
                (insuranceIncomingUnitAmount > 0 || insuranceOutgoingUnitAmount > 0) && (
                  <>
                    {features.PASSENGERS_INSURANCE_ENABLED ? (
                      <InsuranceDialog
                        onAccept={this.selectAllInsurances}
                        onReject={submit}
                        insuranceDuration={insuranceDurationDays}
                        insuranceOutgoingAmount={insuranceOutgoingUnitAmount}
                        insuranceIncomingAmount={insuranceIncomingUnitAmount}
                      />
                    ) : (
                      <ModalTripInsurance
                        onAccept={this.selectAllInsurances}
                        onReject={submit}
                        message={insuranceMessage}
                      />
                    )}
                  </>
                )}

              <Spacing vertical size="XL">
                <FieldArray
                  name="passengers"
                  component={!allowsSeatSelection ? DynamicPassengersForm : StaticPassengersForm}
                  busCategories={busCategories}
                  updatingCategories={isFetchingDetails}
                  autofillEnabled={autofillEnabled}
                  toggleAutofill={this.togglePassengerAutofill}
                  deletePassenger={deletePassenger}
                  isOpenTicket={hasOpenTicket}
                  insuranceOutgoingUnitAmount={insuranceOutgoingUnitAmount}
                  insuranceIncomingUnitAmount={insuranceIncomingUnitAmount}
                  isExchange={isExchange}
                  isRoundTrip={roundTrip}
                  user={user}
                  showPetFriendlySeatsWarn={showPetFriendlySeatsWarn}
                  loyaltyHolderName={this.getLoyaltyHolderName()}
                  isLogged={isLogged}
                  initialValues={initialValues.passengers}
                  dynamicInitialValues={initialValues.passengers}
                  isAccountThePassenger={isAccountThePassenger}
                  autofillFirstPassenger={autofillFirstPassenger}
                />
                <PassengersDisclaimer />
              </Spacing>
            </Form>

            {showMessageNotInsuranceOpenTicket && (
              <OpenTicketNotice
                t={t}
                isReturn={isDepartureOpen}
                messageJustAdult={showMessageJustAdult}
                messageNotInsurance={showMessageNotInsuranceOpenTicket}
              />
            )}

            {exchangeInsuranceValidation &&
              qualifiesForInsurance &&
              !features.PASSENGERS_INSURANCE_ENABLED && (
                <InsuranceAncillarySwitch
                  checked={wantsInsurance}
                  isLoading={updatingInsurance}
                  onChange={this.toggleWantsInsurance}
                  message={insuranceMessage}
                  title={insuranceTitle}
                  price={insuranceAmount}
                  singlePrice={insuranceAmountIsUnitPrice && !showMessageNotInsuranceOpenTicket}
                  plusTaxes
                />
              )}

            {!showPurchaserForm && mobileLoginSection}
            {features.POLICIES_DISCLAIMER_LOCATION === 'PASSENGERS' &&
              (features.SHOW_ACCEPT_POLICIES_CHECKBOX ? (
                <Checkbox
                  id="passenger-policies-checkbox"
                  onChange={this.toggleAcceptTerms}
                  checked={acceptedTerms}
                  label={<PassengerPolicies />}
                />
              ) : (
                <PassengerPolicies />
              ))}
            <FlatFareSwitch />
            <CheckoutNavigation>
              <MainButton
                label={t('general:next')}
                isDisabled={isLoadingNextButton}
                isLoading={isLoadingNextButton}
                onClick={submit}
              />
            </CheckoutNavigation>
          </Spacing>
        </When>
      </div>
    );
  }
}

Passengers.contextType = LoyaltyProgramsContext;
Passengers.propTypes = propTypes;

export default Passengers;
