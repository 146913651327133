import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Gap } from '@reservamos/elements';
import { When } from 'react-if';
import LabelLoading from '../../../ui/molecules/LabelLoading';
import InfoText from '../../../ui/atoms/InfoText';
import AvailableCategoriesList from './AvailableCategoriesLis';
import NotAvailableCategoriesList from './NotAvailableCategoriesLis';

const propTypes = {
  name: PropTypes.string.isRequired,
  passengerCategory: PropTypes.string,
  isFirstPassenger: PropTypes.bool.isRequired,
  busCategories: PropTypes.array.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isExchange: PropTypes.bool,
  passengerBusCategory: PropTypes.string,
};

const defaultProps = {
  isDisabled: false,
};

const PassengerBusCategories = ({
  name,
  passengerCategory,
  isFirstPassenger,
  busCategories,
  isUpdating,
  isDisabled,
  isExchange,
  passengerBusCategory,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('seats');
  const form = useSelector((state) => state.form);
  const {
    passengers: {
      values: { passengers },
    },
  } = form;

  let allowedCategories = busCategories.filter(
    ({ type }) => !features.SPECIAL_CATEGORIES?.includes(type),
  );

  // the first passenger can't be a minor
  if (isFirstPassenger && !isExchange) {
    allowedCategories = allowedCategories.filter((category) => category.type !== 'minor');
  }

  // if child, restrict to minor & general
  if (passengerCategory === 'child') {
    allowedCategories = allowedCategories.filter(
      ({ availability, type }) => availability > 0 && ['minor', 'general'].includes(type),
    );
  }

  const allowedCategoryTypes = allowedCategories.map(({ type }) => type);

  const getAvailableCategories = () => {
    const availableCategories = {};
    allowedCategories.forEach((category) => {
      // eslint-disable-next-line prettier/prettier
      availableCategories[category.type] = passengers.filter(
        (passenger) => passenger.busCategory === category.type,
      ).length;
    });
    return availableCategories;
  };

  const getIsDisabled = (type, availability) => {
    const rest = availability - getAvailableCategories()[type];
    return rest === 0 && passengerBusCategory !== type;
  };

  const renderRadioButton = useCallback(() => {
    const availableCategoriesFiltered = allowedCategories.filter(
      ({ type, availability, blocked, soldOut }) =>
        !soldOut && !getIsDisabled(type, availability) && !blocked,
    );
    const notAvailableCategoriesFiltered = allowedCategories.filter(
      ({ type, availability, blocked, soldOut }) =>
        soldOut || getIsDisabled(type, availability) || blocked,
    );

    return (
      <>
        <AvailableCategoriesList
          isDisabled={isDisabled}
          name={name}
          categories={availableCategoriesFiltered}
        />
        {notAvailableCategoriesFiltered.length ? (
          <NotAvailableCategoriesList categories={notAvailableCategoriesFiltered} />
        ) : null}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedCategories, isDisabled, name, passengers]);

  // if infant or 1 category, don't show this field
  if (passengerCategory === 'infant' || busCategories.length < 2)
    return features.VALID_CATEGORIES?.length ? (
      <InfoText>{t('categories_not_available')}</InfoText>
    ) : null;

  features.VALID_CATEGORIES.forEach((category) => {
    if (isFirstPassenger && category === 'minor') {
      return;
    }
    if (!allowedCategoryTypes.includes(category)) {
      allowedCategories.push({ type: category, soldOut: true });
    }
  });

  if (isUpdating) {
    return (
      <Spacing vertical size="S">
        <Text size="S" weight="semibold">
          {t('category')}
        </Text>
        <LabelLoading text={t('general:loading')} />
      </Spacing>
    );
  }

  return (
    <Spacing vertical>
      <Spacing vertical size="S">
        <Text size="S" weight="semibold">
          {t('category')}
        </Text>
        <Gap alignItems="center">{renderRadioButton()}</Gap>
        <When condition={features.SHOW_INFANTS_DISCLAIMER}>
          {isFirstPassenger && <InfoText>{t('minor_disclaimer')}</InfoText>}
        </When>
      </Spacing>
    </Spacing>
  );
};

PassengerBusCategories.propTypes = propTypes;
PassengerBusCategories.defaultProps = defaultProps;

export default PassengerBusCategories;
