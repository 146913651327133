import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldGroup, Select, Spacing } from '@reservamos/elements';
import moment from 'moment';
import { range } from 'lodash';
import { feedbackClass } from 'utils/formValidations';

function getDays() {
  const days = range(1, 32);

  return days.map((day) => {
    const dayNumber = day.toString().padStart(2, '0');

    return {
      value: dayNumber,
      label: dayNumber,
    };
  });
}

function getMonths() {
  const months = moment.monthsShort();

  return months.map((month, index) => {
    const monthNumber = (index + 1).toString().padStart(2, '0');

    return {
      value: monthNumber,
      label: `${monthNumber} - ${month}`,
    };
  });
}

function getYears(passengerType) {
  const passengerAgeRanges = { adult: 100, child: 19, infant: 2 };
  const currentYear = moment().year();
  const limitYear = currentYear - passengerAgeRanges[passengerType] - 1;

  const years = range(currentYear, limitYear, -1);

  return years.map((year) => ({ value: year, label: year }));
}

const BirthDateField = ({ passengerType, input, meta }) => {
  const { t } = useTranslation('passengers');
  // Assuming input.value is in YYYY-MM-DD format, we split it to get the year, month and day
  const dateArray = input.value.split('-');
  const year = dateArray[0] ?? '';
  const month = dateArray[1] ?? '';
  const day = dateArray[2] ?? '';

  function handleDateChange(type, value) {
    let newDate;

    switch (type) {
      case 'day':
        newDate = `${year}-${month}-${value}`;
        break;
      case 'month':
        newDate = `${year}-${value}-${day}`;
        break;
      case 'year':
        newDate = `${value}-${month}-${day}`;
        break;
      default:
    }

    input.onChange(newDate);
  }

  return (
    <>
      <FieldGroup label={t('birth_date')}>
        <Spacing isResponsive={false} size="XS" flexGrow>
          <Select
            id="day-select"
            placeholder={t('birth_date_day')}
            value={day}
            hasError={Boolean(meta.touched && meta.error && !day)}
            options={getDays()}
            onChange={(e) => handleDateChange('day', e.target.value)}
          />

          <Select
            id="month-select"
            placeholder={t('birth_date_month')}
            value={month}
            hasError={Boolean(meta.touched && meta.error && !month)}
            options={getMonths()}
            onChange={(e) => handleDateChange('month', e.target.value)}
            className={`form-input ${feedbackClass(meta)}`}
          />

          <Select
            id="year-select"
            placeholder={t('birth_date_year')}
            value={year}
            hasError={Boolean(meta.touched && meta.error && !year)}
            options={getYears(passengerType)}
            onChange={(e) => handleDateChange('year', e.target.value)}
          />
        </Spacing>
      </FieldGroup>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field {...input} type="hidden" component="input" />
    </>
  );
};

BirthDateField.propTypes = {
  passengerType: PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  // Redux-form props
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default BirthDateField;
