// @ts-check

import pse from '../../methods/pse';
import { kushkiPseStrategy, mercadoPagoPseStrategy } from '../../methods/pse/strategies';
import pix from '../../methods/pix';
import { mercadoPagoPixStrategy, cieloPixStrategy } from '../../methods/pix/strategies';

/**
 * @typedef {import('./Transfer.js').Transfer} Transfer
 */

const providers = {
  pse,
  pix,
};

const providersStrategies = {
  pse: {
    kushki: kushkiPseStrategy,
    mercadopago: mercadoPagoPseStrategy,
  },
  pix: {
    mercadopago: mercadoPagoPixStrategy,
    cielo: cieloPixStrategy,
  },
};

const TransferFactory = () => {
  /**
   * Creates an instance of a Transfer with the given provider and engine.
   * @function create
   * @returns {Transfer}
   */
  const create = ({ engine, provider }) => {
    const method = providers[provider];
    const strategy = providersStrategies[provider][engine];

    if (!method) {
      throw new Error(`Provider ${provider} not found`);
    }

    if (!strategy) {
      throw new Error(`Provider strategy ${engine} not found`);
    }

    method.setStrategy(strategy);
    return method;
  };

  return { create };
};

const transferFactory = TransferFactory();

export default transferFactory;
