/* eslint-disable import/prefer-default-export */
import store from '@/store';
import { CurrentLang } from 'utils/lang';
import { getSessionId } from 'utils/session';
import { REDIRECT_LOGIN_KEY } from '../constants/sso';

/**
 * Creates the doters URL for auth and register
 * @param {number} registerParam - Param 1 for register 0 for authentication
 * @returns {string} - URL
 */
export function createSSOLoginUrl(registerParam) {
  const { env } = store.getState().whitelabelConfig;
  // The funnel session id is got to use as state param in the sso

  const sessionId = getSessionId();

  if (!sessionId) return;

  localStorage.setItem(REDIRECT_LOGIN_KEY, window.location.href);

  const lang = CurrentLang();
  let url = `${env.sso.host}/?clientId=${env.sso.clientId}`;
  url += `&clientSecret=${env.sso.clientSecret}`;
  url += `&language=${lang}`;
  url += `&state=${sessionId}`;
  url += `&register=${registerParam}`;
  url += `&redirectUri=${env.api.purchaseUrl}/v2/doters/providers-login/${env.brand}`;
  return url;
}

// flat fare discounts names
const SINGLE_FLAT_FARE_DISCOUNT = 'single_trip_flat_fare_discount';
const DEPARTURE_FLAT_FARE_DISCOUNT = 'departure_on_round_trip_flat_fare_discount';
const SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT = 'single_on_round_trip_flat_fare_discount';

/**
 * Retrieves the filter data of a trip.
 * @param {String} way - departure or return
 * @param {Object} trip - trip data
 * @param {Boolean} isRoundTrip - is round trip
 * @returns {Object} The filter flat fare data of the trip.
 */
function getFlatFareData(way, trip, isRoundTrip) {
  const { pricing, departureRoundTripPricing, roundTripPricing } = trip;
  const { meta, discountType } = pricing;

  let flatFareDiscountName = SINGLE_FLAT_FARE_DISCOUNT;
  let discountTypeKey = discountType;
  let flatFareTotal = null;
  if (meta) flatFareTotal = meta.walletPointsRequired;

  if (isRoundTrip) {
    discountTypeKey =
      way === 'departure' ? departureRoundTripPricing.discountType : roundTripPricing.discountType;

    if (
      way === 'departure' &&
      departureRoundTripPricing.discountType === DEPARTURE_FLAT_FARE_DISCOUNT
    )
      flatFareDiscountName = DEPARTURE_FLAT_FARE_DISCOUNT;

    if (way === 'return' && roundTripPricing.discountType === SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT)
      flatFareDiscountName = SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT;

    flatFareTotal =
      way === 'departure'
        ? departureRoundTripPricing.meta?.walletPointsRequired
        : roundTripPricing.meta?.walletPointsRequired;
  }

  const isValid = discountTypeKey === flatFareDiscountName;
  return {
    flatFareTotal,
    isValid,
    flatFareDiscountName: discountTypeKey,
  };
}

/**
 * Calculates the flat fare discount total for a trip.
 * @param {string} way - The trip way ('departure' or 'return').
 * @param {Immutable.Map} trip - The trip to calculate the discount for.
 * @param {boolean} isRoundTrip - Indicates if it's a round trip.
 * @param {Immutable.Map} departureTrip - The departure trip of a round trip.
 * @returns {Object} The flat fare discount total.
 */
export function flatFareDiscountTotal({
  way,
  trip,
  isRoundTrip,
  departureTrip,
  isFlatFareEnabled,
}) {
  if (!isFlatFareEnabled)
    return {
      flatFareValid: false,
    };

  const { flatFareTotal, isValid, flatFareDiscountName } = getFlatFareData(way, trip, isRoundTrip);
  let flatFareValid = isValid ? 'valid' : 'noExists';

  if (way === 'return' && departureTrip && Object.keys(departureTrip).length) {
    const departureTripFlatFareData = getFlatFareData('departure', departureTrip, true);

    if (
      flatFareValid === 'noExists' &&
      departureTripFlatFareData.flatFareDiscountName === DEPARTURE_FLAT_FARE_DISCOUNT
    )
      flatFareValid = 'invalid';

    if (isValid) {
      if (
        (flatFareDiscountName === SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT &&
          departureTripFlatFareData.flatFareDiscountName !== DEPARTURE_FLAT_FARE_DISCOUNT) ||
        (flatFareDiscountName !== SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT &&
          departureTripFlatFareData.flatFareDiscountName === DEPARTURE_FLAT_FARE_DISCOUNT)
      )
        flatFareValid = 'invalid';

      if (
        (flatFareDiscountName === SINGLE_FLAT_FARE_DISCOUNT &&
          departureTripFlatFareData.flatFareDiscountName !== SINGLE_FLAT_FARE_DISCOUNT) ||
        (flatFareDiscountName !== SINGLE_FLAT_FARE_DISCOUNT &&
          DEPARTURE_FLAT_FARE_DISCOUNT === SINGLE_FLAT_FARE_DISCOUNT)
      )
        flatFareValid = 'invalid';

      if (
        flatFareDiscountName !== SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT &&
        flatFareDiscountName !== SINGLE_FLAT_FARE_DISCOUNT &&
        departureTripFlatFareData.flatFareDiscountName !== DEPARTURE_FLAT_FARE_DISCOUNT
      )
        flatFareValid = 'noExists';

      if (
        flatFareDiscountName !== SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT &&
        flatFareDiscountName !== SINGLE_FLAT_FARE_DISCOUNT &&
        departureTripFlatFareData.flatFareDiscountName === DEPARTURE_FLAT_FARE_DISCOUNT
      )
        flatFareValid = 'invalid';

      if (
        flatFareDiscountName === SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT &&
        departureTripFlatFareData.flatFareDiscountName !== DEPARTURE_FLAT_FARE_DISCOUNT &&
        departureTripFlatFareData.flatFareDiscountName !== SINGLE_FLAT_FARE_DISCOUNT
      )
        flatFareValid = 'noExists';
    }
  }

  return {
    flatFareValid,
    flatFareTotal,
  };
}

export const formatDotersPoints = (dotersPoints = 0) => {
  const replacesRegexComas = /\B(?=(\d{3})+(?!\d))/g;
  return dotersPoints.toString().replace(replacesRegexComas, ',');
};
