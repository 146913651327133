import common from './common.json';
import errors from './errors.json';
import footer from './footer.json';
import passengers from './passengers.json';
import general from './general.json';
import notifications from './notifications.json';
import payment from './payment.json';
import purchase from './purchase.json';
import search from './search.json';
import seats from './seats.json';
import trips from './trips.json';
import insurance from './insurance.json';
import loyalty from './loyalty.json';
import siempreplus from './siempreplus.json';
import exchange from './exchange.json';
import pqrs from './pqrs.json';
import user from './user.json';
import invoicing from './invoicing.json';

const language = {
  common,
  errors,
  footer,
  general,
  notifications,
  passengers,
  payment,
  purchase,
  search,
  seats,
  trips,
  insurance,
  loyalty,
  siempreplus,
  exchange,
  pqrs,
  user,
  invoicing,
};

export default language;
