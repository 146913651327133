import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing, Text, FlatButton } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import CoinSvg from 'images/brands/reservamos/wallet/coin.svg';
import useUser from 'hooks/useUser';
import { formatCurrency } from 'utils/Helpers';

function SidebarWalletDetails({ walletAmount }) {
  const { isAuthenticated, loginWithRedirect } = useUser();
  const { t } = useTranslation('loyalty');

  const formattedWalletAmount = formatCurrency(walletAmount, 2);

  return (
    <div className="section-content section-wallet">
      <Spacing size="XS" alignItems="center">
        <Icon type={CoinSvg} size="M" />
        {isAuthenticated ? (
          <Text size="XS" weight="semibold">
            {t('cashback_wallet_summary_message', {
              context: 'authenticated',
              formattedWalletAmount,
            })}
          </Text>
        ) : (
          <Trans
            t={t}
            i18nKey="cashback_wallet_summary_message"
            values={{ formattedWalletAmount }}
            components={{
              flat: (
                <FlatButton size="XS" onClick={loginWithRedirect} weight="semibold">
                  placeholder
                </FlatButton>
              ),
              text: (
                <Text size="XS" weight="semibold" elementType="span">
                  placeholder
                </Text>
              ),
            }}
          />
        )}
      </Spacing>
    </div>
  );
}

SidebarWalletDetails.propTypes = {
  walletAmount: PropTypes.string,
};

SidebarWalletDetails.defaultProps = {
  walletAmount: '0',
};

export default SidebarWalletDetails;
