import store from '@/store';
import { GraphQLClient } from 'graphql-request';

const GET_PROFILE = `
  query Profile {
    profile {
      birthdate
      country
      email
      familyName
      gender
      givenName
      id
      name
      phoneNumber
      picture
      walletBalance
    }
  }
`;

async function fetchUserProfile(accessToken) {
  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const { env } = store.getState().whitelabelConfig;
  const apiAuthUrl = env.api.authUrl;

  const client = new GraphQLClient(apiAuthUrl, { headers });
  return client.request(GET_PROFILE);
}

export default fetchUserProfile;
