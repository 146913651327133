import store from '@/store';
import {
  SET_CARD_ERROR_OCCURED,
  LOADING_OTP,
  OTP_CONFIRMATION,
  OTP_CREATION,
  OTP_ERROR,
  OTP_CLEAR,
} from 'constants/ActionTypes';
import { purchase } from 'js-api-client';
import { camelizeKeys } from 'humps';
import { getDeviceSessionId } from 'utils/OpenPay';
import cybersourceErrorCodes from 'utils/Cybersource';
import { getSessionId } from 'utils/session';
import { getDistinctId } from 'user-analytics';
import { setError } from '.';
import {
  getPurchase,
  updatePurchase,
  receivePurchase,
  receivePayment,
  requestPayment,
  expirePurchase,
} from './purchase';
import availablePaymentEngines from '../constants/PaymentEngine';
import storeFactory from '../../payments/core/factories/store/storeFactory';
import transferFactory from '../../payments/core/factories/transfer';
import cardFactory from '../../payments/core/factories/card';
import { ssoError } from './sso';

function getPaymentPayload(type = 'credit_card', engine, cardValues = {}) {
  return {
    billing_address: cardValues.billingAddress,
    payment_type: type,
    payment_engine: type === 'free_pay' ? undefined : engine,
    tracker_id: getDistinctId(),
    device_session_id: getSessionId(),
    monthly_selected_plan: cardValues.monthlySelectedPlan,
  };
}

function setCardErrorOccured() {
  return {
    type: SET_CARD_ERROR_OCCURED,
  };
}

export function loadingOTP({ loading, showOTPModal }) {
  return { type: LOADING_OTP, payload: { loading, showOTPModal } };
}

export function otpCreation({ showOTPModal }) {
  return { type: OTP_CREATION, payload: { loading: false, showOTPModal } };
}

export function otpConfirmation({ otpConfirmed, showOTPModal }) {
  return { type: OTP_CONFIRMATION, payload: { otpConfirmed, showOTPModal } };
}

export function otpError({ otpError, otpErrorCode }) {
  return { type: OTP_ERROR, payload: { otpError, otpErrorCode } };
}

function getOTPEndpoint() {
  const { walletType } = store.getState().purchase.toJS();
  return walletType;
}

/**
 * Clean the OTP state
 */
export function clearOTP() {
  return { type: OTP_CLEAR };
}

export function generateOTP(pointsUsed) {
  return (dispatch) => {
    const {
      whitelabelConfig: { features },
    } = store.getState();
    dispatch(loadingOTP({ loading: true, showOTPModal: false }));
    const payload = { max_redemption_points: pointsUsed };
    purchase
      .generateOTP(getOTPEndpoint(), payload)
      .then(() => {
        dispatch(otpCreation({ showOTPModal: true }));
      })
      .catch((error) => {
        const { code } = error;
        dispatch(loadingOTP({ loading: false, showOTPModal: false }));
        if (code === 401 && features.USE_SSO) {
          dispatch(ssoError(error.code));
        } else {
          dispatch(setError(code, 'otp_creation_error', 'error', false));
        }
      });
  };
}

export function confirmOTP(otp) {
  return (dispatch) => {
    const {
      whitelabelConfig: { features },
    } = store.getState();
    dispatch(loadingOTP({ loading: true, showOTPModal: true }));
    purchase
      .validateOTP(getOTPEndpoint(), otp)
      .then(() => {
        dispatch(otpConfirmation({ otpConfirmed: true, showOTPModal: false }));
      })
      .catch((error) => {
        const { code } = error;
        dispatch(otpConfirmation({ otpConfirmed: false, showOTPModal: true }));
        if (code === 401 && features.USE_SSO) {
          dispatch(ssoError(code));
        } else {
          dispatch(otpError({ otpError: 'otp_confirmation_error', otpErroCode: code }));
        }
      });
  };
}

function paymentFailureHandler(dispatch, error, storePayment = false) {
  const { features } = store.getState().whitelabelConfig;
  dispatch(updatePurchase(false));
  dispatch(receivePayment());
  if (error.humanErrorReason) {
    dispatch(setError(301, 'null', 'warning', false, error.humanErrorReason));
    dispatch(setCardErrorOccured());
  } else if (error.engine === 'cybersource' && error.reason) {
    dispatch(
      setError(
        301,
        cybersourceErrorCodes[error.reason] || cybersourceErrorCodes.default,
        'warning',
        false,
      ),
    );
  } else if (error.code === 403) {
    dispatch(setError(301, 'finished_time_for_purchase', 'warning', false));
    dispatch(expirePurchase());
  } else if (error.code === 401 && features.USE_SSO) {
    dispatch(ssoError(error.code));
  } else if (storePayment) {
    dispatch(setError(301, 'choose_other_seats', 'warning', false));
  } else if ((error.data && error.data.category === 'request') || error.message_to_purchaser) {
    dispatch(setError(300, 'check_your_card_details', 'warning', false));
  } else {
    dispatch(setError(301, 'error_generating_payment', 'warning', false));
    dispatch(setCardErrorOccured());
  }


}

function paymentPollingHandler(dispatch, purchaseToken, response) {
  const { status, payload } = camelizeKeys(response);
  const { purchaseState, using3dSecure, humanErrorReason } = payload;

  if ((payload.status === 'pending' && using3dSecure) || purchaseState !== 'attempt') {
    dispatch(receivePayment(payload));
    dispatch(getPurchase(purchaseToken));
  } else if (!['pending', 'completed'].includes(status)) {
    // PIX CASE
    if (
      purchaseState === 'attempt' &&
      payload.paymentType === 'transfer' &&
      payload.status === 'pending'
    ) {
      dispatch(receivePayment(payload));
      dispatch(getPurchase(purchaseToken));
    } else if (status === 'retries_exceeded') {
      dispatch(setError(301, 'error_generating_payment', 'warning'));

    } else if (status === 'aborted') {
      dispatch(receivePayment(payload));
      dispatch(getPurchase(purchaseToken));
    } else {
      // ELSE, is an error of some kind
      paymentFailureHandler(
        dispatch,
        (humanErrorReason && { humanErrorReason }) || new Error('Payment Polling Error'),
      );
    }
  }
}

export function mercadoPagoPaymentAttempt(mercadoPagoFormData) {
  const { token, installments, mpDeviceSessionId, paymentMethodId, issuerId } = mercadoPagoFormData;
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');
    const paymentPayload = {
      payment_type: 'credit_card',
      payment_engine: availablePaymentEngines.mercadoPago,
      tracker_id: getDistinctId(),
      device_session_id: mpDeviceSessionId,
      credit_card_tokens: {
        mercadopago_token: token,
      },
      credit_card_token: token,
      monthly_selected_plan: Number(installments),
      card_brand: paymentMethodId,
      card_bank_code: issuerId,
    };
    dispatch(requestPayment());
    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        onReceivePayment: (response) => {
          paymentPollingHandler(dispatch, purchaseToken, response);
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function efectyPaymentAttempt() {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');
    const paymentPayload = {
      payment_type: 'store',
      payment_engine: availablePaymentEngines.mercadoPago,
      payment_provider: 'efecty',
      tracker_id: getDistinctId(),
      device_session_id: getDeviceSessionId(),
      monthly_selected_plan: 1,
    };
    dispatch(requestPayment());
    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        onReceivePayment: (response) => {
          paymentPollingHandler(dispatch, purchaseToken, response);
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function cardPaymentAttempt(purchaseToken, cardValues, paymentInfo) {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    // this is the new way to get the payment engine for each payment method
    const selectedPaymentMethod = purchaseState.get('selectedPaymentMethod');
    const extra = selectedPaymentMethod?.extra ?? {};
    // defaults to purchase payment engine for backwards compatibility
    const paymentEngine = selectedPaymentMethod?.engine ?? purchaseState.get('paymentEngine');
    const cardPayment = cardFactory.create({ engine: paymentEngine });

    return cardPayment
      .createPayload(cardValues, { ...extra, ...paymentInfo })
      .then((enginePayload) => {
        const paymentPayload = {
          ...getPaymentPayload('credit_card', paymentEngine, cardValues),
          ...enginePayload,
        };

        return purchase.createPayment(purchaseToken, paymentPayload, {
          onReceivePayment: (response) => paymentPollingHandler(dispatch, purchaseToken, response),
        });
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function storePaymentAttempt({ token, ...fields }) {
  return (dispatch, getState) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    // this is the new way to get the payment engine for each payment method
    const selectedPaymentMethod = getState().purchase.get('selectedPaymentMethod');
    // defaults to kushki for backwards compatibility
    const paymentEngine = selectedPaymentMethod?.engine ?? 'kushki';
    const storePayment = storeFactory.create({ engine: paymentEngine });

    return storePayment
      .getPayload(fields)
      .then((enginePayload) => {
        const payload = {
          ...getPaymentPayload('store', paymentEngine),
          ...enginePayload,
        };

        return purchase
          .createPayment(token, payload, {
            onReceivePayment: (response) => paymentPollingHandler(dispatch, token, response),
          })
          .catch((error) => paymentFailureHandler(dispatch, error, true));
      })
      .catch((error) => paymentFailureHandler(dispatch, error, true));
  };
}

export function paymentAttempt(token, type) {
  return (dispatch) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    if (type === 'paypal') {
      return purchase
        .createPayment(token, getPaymentPayload(type, type), { init: false })
        .then(({ payload }) => dispatch(receivePayment(payload)))
        .catch((error) => paymentFailureHandler(dispatch, error));
    }

    return purchase
      .createPayment(token, getPaymentPayload(type), {
        onReceivePayment: (response) => paymentPollingHandler(dispatch, token, response),
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function thirdPartyPaymentAttempt(token, { engine, type }) {
  return (dispatch) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    return purchase
      .createPayment(token, getPaymentPayload(type, engine), { init: false })
      .then(({ payload }) => dispatch(receivePayment(payload)))
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function getPayment(paymentId, purchaseToken) {
  return (dispatch) => {
    dispatch(requestPayment());
    // return a promise to wait for
    return Promise.all([purchase.get(purchaseToken), purchase.getPayment(purchaseToken, paymentId)])
      .then(([purchaseResponse, paymentResponse]) => {
        dispatch(receivePurchase(purchaseResponse));
        dispatch(receivePayment(paymentResponse));
      })
      .catch((error) => {
        dispatch(setError(304, 'error_when_looking_for_payment', 'error', true));
        dispatch(receivePayment());
        throw new Error(`Error 304: Failed to get payment. ${error.message}`);
      });
  };
}

export function transferPaymentResult({ purchaseToken, paymentId, minutes = 21, interval = 2 }) {
  return new Promise((resolve, reject) => {
    const pollOptions = {
      interval: interval * 1000,
      maxRetries: (minutes * 60) / interval,
    };

    const onReceivePayment = (poll) => {
      if (!['pending', 'completed'].includes(poll.status)) {
        return reject(camelizeKeys(poll.payload));
      }

      const status = poll.payload.kushki_processor_state;
      const purchaseState = poll.payload.purchase_state;
      const paymentEngine = poll.payload.payment_engine;
      switch (status) {
        case 'OK':
          if (purchaseState !== 'attempt') {
            resolve(camelizeKeys(poll.payload));
          }
          break;
        case 'PENDING':
          break;
        default:
          /**
           * When the user cancels the payment (using Mercado Pago) by going back from the PSE page to the checkout
           * the Purchase API doesn't set the kushki_processor_state property,
           * this causes the poll to fail and the promise to be rejected.
           *
           * This is a workaround to avoid the rejection of the promise until the poll.status is "aborted"
           */
          if (paymentEngine === 'mercadopago' && poll.status === 'pending') {
            break;
          }
          reject(camelizeKeys(poll.payload));
          break;
      }
    };

    purchase.getPayment(purchaseToken, paymentId, {
      onReceivePayment,
      options: pollOptions,
    });
  });
}

export function pollTransferPayment(paymentConfig) {
  return (dispatch) => {
    transferPaymentResult(paymentConfig)
      .then((response) => {
        dispatch(receivePayment(response));
      })
      .catch((response) => {
        const error = response.kushkiProcessorState;

        if (error === 'NOT_AUTHORIZED') {
          dispatch(setError(300, 'transaction_declined', 'warning', false));
        } else if (error === 'FAILED') {
          dispatch(setError(301, 'transaction_failed', 'warning', false));
        } else {
          dispatch(setError(301, 'error_generating_payment', 'warning', false));
        }

        /**
         * For some reason, the payment data can be returned either in the response.payload or directly in the response.
         * This was causing a bug that prevented the user from being redirected back to the checkout when the payment failed.
         */
        dispatch(receivePayment(response.payload ? response.payload : response));
        dispatch(updatePurchase(false));
      });
  };
}

export function confirmPaypalPayment(purchaseToken, paymentId) {
  return (dispatch) => {
    dispatch(requestPayment());

    return purchase
      .confirmPaypalPayment(purchaseToken, paymentId, {
        onReceivePayment: (response) => {
          if (response.payload.status === 'failed') {
            // Manually navigate to the failed payment page
            window.location = `/payment/${purchaseToken}/${paymentId}/failure`;
          } else {
            paymentPollingHandler(dispatch, purchaseToken, response);
          }
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

export function transferPaymentAttempt(config) {
  return async (dispatch, getState) => {
    try {
      const { engine, provider, purchaseToken, bankId } = config;
      const transferFactoryPayload = engine
        ? { engine, provider }
        : { engine: 'kushki', provider: 'pse' };
      const transfer = transferFactory.create(transferFactoryPayload);

      dispatch(updatePurchase(true));
      dispatch(requestPayment());

      const envConfig = getState().whitelabelConfig.env;
      const token = await transfer.createToken({
        ...config,
        currency: envConfig.currency,
        callbackUrl: `${envConfig.api.purchaseUrl}/v2/kushki/transfer_in_redirect`,
      });

      /**
       * @todo Add a method called getEntityTypes(personType) to the Transfer template
       * and remove this conditional when the method is implemented
       */
      const mercadoPagoEntityTypes = ['individual', 'association'];
      const isMercadoPago = engine === 'mercadopago';
      const entityType = isMercadoPago ? mercadoPagoEntityTypes[config.personType] : '';

      const createTransferPayload = {
        payment_type: 'transfer',
        payment_engine: engine || 'kushki',
        payment_provider: provider,
        tracker_id: getDistinctId(),
        device_session_id: getDeviceSessionId(),
        monthly_selected_plan: 1,
        transfer_token: token,
        bank_id: bankId,
        entity_type: entityType,
      };

      const response = await purchase.createPayment(purchaseToken, createTransferPayload, {
        init: false,
      });

      if (transfer.needsRedirect()) {
        window.location = response.payload.redirect_to;
      } else {
        paymentPollingHandler(dispatch, purchaseToken, response);
      }
    } catch (error) {
      paymentFailureHandler(dispatch, error);
    }
  };
}

export function evertecPaymentAttempt({ purchaseToken, type, provider, requestId }) {
  return (dispatch) => {
    try {
      const paymentPayload = {
        payment_engine: 'evertec',
        payment_type: type,
        payment_provider: provider,
        tracker_id: getDistinctId(),
        device_session_id: getDeviceSessionId(),
        reference_id: requestId,
      };
      return purchase
        .createPayment(purchaseToken, paymentPayload, { init: false })
        .then(({ payload }) => dispatch(receivePayment(payload)))
        .catch((error) => paymentFailureHandler(dispatch, error));
    } catch (error) {
      paymentFailureHandler(dispatch, error);
    }
  };
}

export function confirmEvertecPayment() {
  return (dispatch, getState) => {
    dispatch(requestPayment());
    try {
      const { purchase: purchaseState, payment: paymentState } = getState();
      const token = purchaseState.get('token');
      const paymentId = paymentState.get('id');
      return purchase.getPayment(token, paymentId).then((response) => {
        dispatch(receivePayment(response));
        dispatch(getPurchase(token));
      });
    } catch (error) {
      paymentFailureHandler(dispatch, error);
    }
  };
}
