import { connect } from 'react-redux';
import { UPDATE_SERVICE_LEVEL } from 'constants/ActionTypes';
import { updatePurchaseFields } from '../../../actions/purchase';
import ServiceLevels from './ServiceLevels';

const mapStateToProps = ({ purchase, whitelabelConfig }) => {
  const { selectedServiceLevel, token, config, updatingServiceLevel } = purchase.toJS();

  const { features } = whitelabelConfig;

  const { serviceLevels } = config;

  return {
    isUpdating: updatingServiceLevel,
    selectedServiceLevel,
    serviceLevels,
    token,
    areServiceLevelsEnabled: features.SERVICE_LEVELS_ENABLED,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateServiceLevel: (token, serviceLevelId) =>
    dispatch(updatePurchaseFields(token, serviceLevelId, UPDATE_SERVICE_LEVEL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLevels);
