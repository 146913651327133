import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Icon, Badge, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { getTripDiscountPercent } from 'utils/Helpers';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { discountTypes } from 'models/parseTrip';
import { isIAMSA } from 'utils/Reserbus';

/**
 * Renders the discount advice component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.discountType - The type of discount.
 * @param {number} props.discountAvailability - The availability of the discount.
 * @param {number} props.discountAmount - The provider discount.
 * @param {number} props.tripTotal - The total trip amount.
 * @param {string} props.way - The trip way.
 * @returns {JSX.Element} The rendered component.
 */
const TripDiscountAdvice = ({
  discountType,
  discountAvailability,
  discountAmount = 0,
  tripTotal,
  way,
}) => {
  const { VISIBLE_DISCOUNTS_FOR_RESULTS } = useWhitelabelFeatures();
  const { brand } = useWhitelabelEnvs();
  const { t } = useTranslation('trips');

  if (!discountTypes[discountType] || !VISIBLE_DISCOUNTS_FOR_RESULTS?.includes(discountType))
    return null;

  const isIAMSABrand = isIAMSA(brand);
  const showAvailabilityAndDiscount = !isIAMSABrand;
  const availabilityContext = isIAMSABrand ? 'iamsa' : brand;

  const discountPercent = discountAmount
    ? getTripDiscountPercent({ tripTotal, discountAmount, way })
    : '';

  const discountAvailabilityText =
    discountAvailability && discountAvailability <= 5
      ? `${t('trips:label.places', { count: discountAvailability, context: availabilityContext })}`
      : null;

  const discountLabel = discountTypes[discountType]?.inLabel;
  const discountText = discountLabel
    ? `${t(discountLabel, { context: brand })} ${discountPercent}`
    : discountPercent;

  const discountIcon = discountTypes[discountType]?.icon?.[brand];

  return (
    <Spacing size="XS" alignItems="center">
      {discountIcon && <Icon type={discountIcon} size="M" mobileSize="S" />}

      {(showAvailabilityAndDiscount || discountAvailabilityText) && (
        <Badge type="discount" alphaBg="S" roundCorners>
          <Text elementType="span" size="XS" mobileSize="XXS" color="discount" weight="bold" italic>
            {showAvailabilityAndDiscount && `${discountText} `}
            {discountAvailabilityText && (
              <Text
                elementType="span"
                size="XS"
                mobileSize="XXS"
                color="discount"
                weight="regular"
                italic
              >
                {discountAvailabilityText}
              </Text>
            )}
          </Text>
        </Badge>
      )}
    </Spacing>
  );
};

TripDiscountAdvice.propTypes = {
  discountType: PropTypes.string,
  discountAvailability: PropTypes.number,
  discountAmount: PropTypes.number,
  tripTotal: PropTypes.number,
  way: PropTypes.string,
};

export default TripDiscountAdvice;
