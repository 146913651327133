import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, Icon, LinkButton } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import 'styles/components/BenefitsInvoicing';
import slBasic from 'images/icons/sl-basic.svg';
import slMax from 'images/icons/sl-max.svg';

/**
 * BenefitsInvoicing component
 *
 * This component displays the benefits of invoicing based on the service level.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.url - The URL for the CTA button
 * @param {Object} props.serviceLevel - The service level object
 * @param {string} props.serviceLevel.name - The name of the service level
 * @returns {JSX.Element} The rendered component
 */
const BenefitsInvoicing = ({ url, serviceLevel }) => {
  const { t } = useTranslation(['invoicing', 'purchase']);

  return (
    <Spacing vertical>
      <Text color="primary">{t('benefits.title')}</Text>

      <Text>{t('benefits.description')}</Text>
      <Spacing fullWidth flexGrow>
        {serviceLevel && (
          <div className="invoice-process-benefits">
            <Icon size="M" type={serviceLevel.name === 'basic' ? slBasic : slMax} />

            <Text size="S">
              {t(`purchase:service-levels.${serviceLevel?.name}`, { context: 'title' })}
            </Text>
          </div>
        )}
        <div className="invoice-process-benefits">
          <Text size="S">{t('purchase:label.service_charge')}</Text>
        </div>
      </Spacing>

      <LinkButton
        newTab
        fullWidth
        variant="accent"
        text={t('benefits.cta')}
        buttonType="submit"
        href={url}
      />
    </Spacing>
  );
};

BenefitsInvoicing.propTypes = {
  url: PropTypes.string.isRequired,
  serviceLevel: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default BenefitsInvoicing;
