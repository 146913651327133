import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getPurchaseStepName from 'utils/purchase/getPurchaseStepName';
import { formatPercent } from 'utils/Helpers';

/**
 * Calculate the pricing for a trip.
 *
 * @param {Object} options - The options for calculating the pricing.
 * @param {Object} options.pricing - The pricing information for the trip.
 * @param {number} options.passengersCount - The number of passengers.
 * @returns {Object} The calculated trip pricing.
 */
export const getTripPricing = ({ pricing = {}, passengersCount }) => {
  const amountPerPassenger = pricing.amount || 0;
  const discountPerPassengers = pricing.providerDiscount?.amount || 0;
  const totalPerPassenger = pricing.total || 0;
  const breakdown = pricing.breakdown || {};
  const luggagePerPassenger = breakdown.equipaje || 0;
  const networksPerPassenger = breakdown.redes || 0;
  const insurancePerPassenger = breakdown.seguros || 0;
  const taxesFromBreakdown = breakdown.impuestos || 0;
  const taxesFromPricing = pricing.taxes || 0;
  const taxesPerPassenger = taxesFromPricing > 0 ? taxesFromPricing : taxesFromBreakdown;

  const total = totalPerPassenger * passengersCount;

  const totalPercentDiscount =
    pricing.totalBeforeDiscount && formatPercent(pricing.totalBeforeDiscount, pricing.total);

  return {
    amount: amountPerPassenger * passengersCount,
    total,
    totalPercentDiscount,
    totalBeforeDiscount: pricing.totalBeforeDiscount * passengersCount,
    discount: discountPerPassengers * passengersCount,
    discountType: pricing.discountType,
    passengersCount,
    luggage: luggagePerPassenger * passengersCount,
    taxes: taxesPerPassenger * passengersCount,
    networks: networksPerPassenger * passengersCount,
    insurance: insurancePerPassenger * passengersCount,
  };
};

/**
 * Custom hook for calculating pricing before checkout.
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.isRoundTrip - The flag to indicate if the trip is a round trip.
 */
const usePricingBeforeCheckout = ({ isRoundTrip }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const pricingBeforeCheckoutIsOn = features.PRICING_BEFORE_CHECKOUT;
  const departs = useSelector((state) => state.purchase.toJS().departs);
  const returns = useSelector((state) => state.purchase.toJS().returns);
  const location = useLocation();
  const purchaseStep = getPurchaseStepName(location.pathname);
  const isBeforeCheckout = ['dSeats', 'rSeats', 'passengers'].includes(purchaseStep);

  const passengersCountForm = useSelector(
    (state) => state?.form?.passengers?.values?.passengers,
  )?.length;

  const passengersCount = departs?.selectedSeats?.length || passengersCountForm || 0;

  // When is a round trip the pricing key is different
  const departPricingPerPassenger = isRoundTrip
    ? departs?.fragments[0]?.departureRoundTripPricing
    : departs?.fragments[0]?.pricing;
  const departTripPricing = getTripPricing({ pricing: departPricingPerPassenger, passengersCount });

  // When is a round trip the pricing key is different
  const returnPricingPerPassenger = (returns?.fragments || [])[0]?.roundTripPricing;
  const returnTripPricing = returnPricingPerPassenger
    ? getTripPricing({ pricing: returnPricingPerPassenger, passengersCount })
    : { amount: 0, discount: 0, total: 0, luggage: 0, taxes: 0, networks: 0, insurance: 0 };

  return {
    passengersCount,
    displayPricingBeforeCheckout: isBeforeCheckout && pricingBeforeCheckoutIsOn,
    departTripPricing,
    returnTripPricing,
    purchaseTotal: departTripPricing.total + returnTripPricing.total,
  };
};

export default usePricingBeforeCheckout;
