import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import store from '@/store';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { addTripDetails } from '../../actions/trip';
import fetchTripPath from '../../api/fetchTripPath';
import LabelLoading from '../../ui/molecules/LabelLoading';
import parseTripDetails from '../../utils/trips/parseTripDetails';
import TimeLine from '../TimeLine';

/**
 * Component used as part of trip detail modal.
 * @param {Object} props - Object props
 * @param {string} props.tripId - Indicates current trip id.
 * @param {array} props.itinerary - Object containing all trip information. Used after page reload once inside purchase flow.
 * @returns {JSX.Element} A React component that renders the itinerary of current the selected trip.
 */
const ResultItinerary = ({ tripId, itinerary }) => {
  const { t } = useTranslation(['search', 'purchase']);
  const [loading, setLoading] = useState(false);
  const { departure, return: returns } = useSelector((state) => state.search).toJS();
  const tripsStateJS = useSelector((state) => state.trips).toJS();
  let rawTrip;

  if (Object.keys(tripsStateJS).length !== 0) {
    const tripsDeparture = tripsStateJS[departure.id].buses.trips;
    const tripsReturn = tripsStateJS[returns.id]?.buses.trips;
    rawTrip =
      tripsDeparture.find((trip) => trip.id === tripId) ||
      tripsReturn?.find((trip) => trip.id === tripId);
  } else {
    const { fragments } = itinerary;
    const [fullTrip] = fragments;
    rawTrip = fullTrip;
  }
  const shouldFetchTripPath = rawTrip.stops > 0;

  /**
   * Sets a fixed default route taking trip information
   * to display when tenant doesn't have path details enabled for api request
   */
  const fixedRoute = [
    {
      type: 'departure',
      time: moment(rawTrip.departure).format('LT'),
      title: rawTrip.origin.cityName,
      description: `${t('trips:leaves_from')} ${rawTrip.origin.name}`,
    },
    {
      type: 'end',
      time: moment(rawTrip.arrival).format('LT'),
      title: rawTrip.destination.cityName,
      description: `${t('trips:arrives_in')} ${rawTrip.destination.name}`,
    },
  ];

  const { tripsDetails } = store.getState();
  const { trips } = tripsDetails.toJS();
  const trip = trips[tripId] || {};
  const { route } = trip;

  const [details, setDetails] = useState({
    route: !shouldFetchTripPath ? fixedRoute : route,
    originDetails: {
      address: rawTrip.originAddress,
      coordinates: rawTrip?.origin.coordinates,
    },
    destinationDetails: {
      address: rawTrip.destinationAddress,
      coordinates: rawTrip?.destination?.coordinates,
    },
  });

  useEffect(() => {
    if (!shouldFetchTripPath) {
      return;
    }
    if (!details.route) {
      setLoading(true);
      fetchTripPath(tripId)
        .then((response) => {
          let tripDetail = parseTripDetails(response);
          if (!tripDetail.route) {
            tripDetail = { route: fixedRoute };
          }
          store.dispatch(addTripDetails(tripId, tripDetail));
          setDetails({
            route: tripDetail.route,
            originDetails: {
              address: rawTrip.originAddress ?? rawTrip.origin.address,
              coordinates: rawTrip.origin.coordinates,
            },
            destinationDetails: {
              address: rawTrip.destinationAddress ?? rawTrip.destination.address,
              coordinates: rawTrip.destination.coordinates,
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (!details.originDetails.coordinates) {
      setDetails({
        route: details.route,
        originDetails: {
          address: rawTrip.originAddress,
          coordinates: rawTrip.origin.coordinates,
        },
        destinationDetails: {
          address: rawTrip.destinationAddress,
          coordinates: rawTrip.destination.coordinates,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId, details]);
  if (loading) return <LabelLoading text={t('loading_itinerary')} />;
  if (!details.route) return <Text>{t('text.do_not_show_itinerary')}</Text>;
  return <TimeLine route={details} />;
};

ResultItinerary.propTypes = {
  tripId: PropTypes.string.isRequired,
  itinerary: PropTypes.shape({
    transportType: PropTypes.string.isRequired,
    fragments: PropTypes.array.isRequired,
  }),
};

export default ResultItinerary;
