import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import usePurchase from 'hooks/store/usePurchase';
import ManualInvoicing from './ManualInvoicing';
import ProviderInviocing from './ProviderInvoicing';
import BenefitsInvoicing from './BenefitsInvoicing';

/**
 * InvoicingProcess component
 *
 * This component handles the invoicing process based on the invoice process type.
 * It redirects to the billing URL if the process is automatic, otherwise it displays
 * the appropriate manual or provider invoicing components.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.billingEmail - The billing email address
 * @param {string} props.billingUrl - The billing URL
 * @param {string} props.rfc - The RFC (tax identification number)
 * @returns {JSX.Element} The rendered component
 */
function InvoicingProcess({ billingEmail, billingUrl, rfc }) {
  const { t } = useTranslation('invoicing');
  const { createdAt, token, selectedServiceLevel, invoice } = usePurchase();

  const { invoiceProcess, urls } = invoice;

  const rfc64 = btoa(rfc);
  const token64 = btoa(token);

  const url = `${billingUrl}?mprs=${rfc64}&cdafb=${token64}`;

  const forceManualProcess = moment(createdAt).isBefore(moment('2023-08-18'));

  useEffect(() => {
    if (invoiceProcess === 'auto') {
      window.location.href = url;
    }
  }, [invoiceProcess, url]);

  if (invoiceProcess === 'auto') return null;

  return (
    <>
      {invoiceProcess === 'manual' || forceManualProcess ? (
        <Spacing vertical alignItems="center">
          <ManualInvoicing billingEmail={billingEmail} />
        </Spacing>
      ) : (
        <Spacing flexGrow fullWidth justifyContent="center" isResponsive size="L">
          <Spacing vertical justifyContent="space-between">
            <Text color="primary">{t('provider.title')}</Text>

            <Spacing vertical>
              <Text elementType="span">
                <Trans
                  t={t}
                  i18nKey="provider.description"
                  components={{
                    text: (
                      <Text elementType="span" color="grayMedium">
                        placeholder
                      </Text>
                    ),
                    bold: (
                      <Text elementType="span" weight="bold">
                        placeholder
                      </Text>
                    ),
                  }}
                />
              </Text>
            </Spacing>

            <Spacing fullWidth>
              {invoiceProcess === 'semi-manual' ? (
                <ManualInvoicing billingEmail={billingEmail} />
              ) : (
                <ProviderInviocing urls={urls} />
              )}
            </Spacing>
          </Spacing>

          <Spacing vertical justifyContent="space-between">
            <BenefitsInvoicing url={url} serviceLevel={selectedServiceLevel} />
          </Spacing>
        </Spacing>
      )}
    </>
  );
}

InvoicingProcess.propTypes = {
  billingEmail: PropTypes.string,
  billingUrl: PropTypes.string,
  rfc: PropTypes.string,
};

InvoicingProcess.defaultProps = {
  billingEmail: '',
  billingUrl: '',
  rfc: '',
};

export default InvoicingProcess;
