import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { setUsingWallet, updatePurchaseUser } from '../../../actions/purchase';
import CheckoutWallet from './CheckoutWallet';

const mapStateToProps = ({ purchase, siemprePlus }) => {
  const { user } = siemprePlus.toJS();

  return {
    purchaseToken: purchase.get('token'),
    updatingWallet: purchase.get('updatingWallet'),
    maxUsableBalance: purchase.get('maxUsableBalance'),
    usingWallet: Boolean(purchase.get('walletBalanceUsed')),
    walletBalanceUsed: purchase.get('walletBalanceUsed'),
    selectedPaymentOption: purchase.get('selectedPaymentOption'),
    chosenSpPassengerId: purchase.get('chosenSpPassengerId'),
    isExchange: purchase.get('isExchange'),
    total: purchase.get('total'),
    // walletType: purchase.get('walletType'),
    // ? WalletType is setted with a fucntion, in our case we only have ony type of wallet
    // ? it'd be better to get the prop already defined.
    walletType: 'cashbackWallet',
    walletAmount: purchase.get('walletAmount'),
    walletBalanceTentative: purchase.get('walletBalanceTentative'),
    user,
  };
};

const mapDispatchToProps = {
  setUsingWallet,
  updatePurchaseUser,
};

export default compose(
  withTranslation('Purchase'),

  connect(mapStateToProps, mapDispatchToProps),
)(CheckoutWallet);
