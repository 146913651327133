import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, LinkButton } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

/**
 * ProviderInvoicing component
 *
 * This component displays a list of provider invoicing URLs with corresponding
 * call-to-action buttons.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array<string>} props.urls - The array of invoicing URLs
 * @returns {JSX.Element} The rendered component
 */
const ProviderInviocing = ({ urls }) => {
  const { t } = useTranslation('invoicing');

  return (
    <Spacing vertical fullWidth>
      {urls.map((url, i) => {
        return (
          <Spacing vertical size="S" key={url}>
            <Text size="S">{t('provider.invoice', { count: i + 1 })}</Text>

            <LinkButton
              newTab
              fullWidth
              variant="accent"
              text={t('provider.cta')}
              buttonType="submit"
              href={url}
            />
          </Spacing>
        );
      })}
    </Spacing>
  );
};

ProviderInviocing.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
};

export default ProviderInviocing;
