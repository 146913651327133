import React from 'react';
import PropTypes from 'prop-types';
import { formatDuration, formatCurrency } from 'utils/Helpers';
import StarRating from 'components/StarRating';
import ProviderItemBrand from 'components/search/ProviderItemBrand';
import DisclaimerTooltip from 'components/DisclaimerTooltip';
import InstallmentsTooltip from 'components/InstallmentsTooltip';
import { withTranslation } from 'react-i18next';
import { getCurrencySuffix } from 'utils/currency';
import { Button } from '@reservamos/elements';

const propTypes = {
  provider: PropTypes.shape({
    ally: PropTypes.bool.isRequired,
    transportType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    copyrightProtected: PropTypes.bool.isRequired,
    lineUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    lowestPrice: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    averageRating: PropTypes.number,
    hasDiscounts: PropTypes.bool.isRequired,
    departures: PropTypes.array.isRequired,
    tripCount: PropTypes.number.isRequired,
    mixedProviders: PropTypes.array,
  }).isRequired,
  roundTrip: PropTypes.bool.isRequired,
  way: PropTypes.string.isRequired,
  installmentsMinAmount: PropTypes.number.isRequired,
  onSelectProvider: PropTypes.func.isRequired,
  departuresDescription: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const ProviderItem = (props) => {
  const {
    provider,
    roundTrip,
    way,
    installmentsMinAmount,
    departuresDescription,
    onSelectProvider,
    t,
  } = props;
  const {
    id,
    transportType,
    ally,
    lowestPrice,
    duration,
    averageRating,
    hasDiscounts,
    tripCount,
    mixedProviders,
  } = provider;

  let tripType = roundTrip ? 'round' : '';
  if (roundTrip && way === 'return') {
    tripType = 'return';
  }

  let transportIconClasses = [
    {
      iconClass: `transport-${transportType}`,
      id: `${id}-0`,
    },
  ];
  const priceDescription = (
    <span className="trip-item">
      <em>{t('search:provider_item.from', { context: tripType })}</em>
    </span>
  );

  if (transportType === 'mixed') {
    transportIconClasses = mixedProviders.map(({ id, transportType }, index) => ({
      iconClass: `transport-${transportType}`,
      id: `${id}-${index}`,
    }));
  }

  return (
    <div className="result-box-container animated fade-in">
      <div className="js-mobile-click" onClick={onSelectProvider} />

      <div className="result-box providers-results">
        {hasDiscounts ? <i className="result-box-sale" /> : null}

        <i className={`result-box-arrow ${hasDiscounts ? 'sale' : ''}`} />

        <div className="result-box-main">
          {!ally ? <DisclaimerTooltip /> : null}

          <ProviderItemBrand provider={provider} />

          <StarRating rating={averageRating} grayScale />
        </div>

        <div className="result-box-price">
          <span className="trip-item">{priceDescription}</span>

          <span className={`provider-price ${hasDiscounts ? 'sale' : ''}`}>
            {`${formatCurrency(lowestPrice)} `}
            <small>{getCurrencySuffix()}</small>
          </span>

          <InstallmentsTooltip installmentsMinAmount={installmentsMinAmount} />
        </div>

        <div className="result-box-summary">
          <div className="result-box-transport">
            <ul className="summary-trip-icons">
              {transportIconClasses.map(({ iconClass, id }) => (
                <li key={id}>
                  <i className={iconClass} />
                </li>
              ))}
            </ul>

            <span className="trip-item">{formatDuration(duration)}</span>
          </div>

          <div className="result-box-departures">
            <span className="trip-item bold">
              {`${tripCount} ${tripCount === 1 ? 'salida' : 'salidas'}`}
            </span>

            <span className="trip-item">{departuresDescription}</span>
          </div>
        </div>

        <div className="result-box-action">
          <Button
            variant={hasDiscounts ? 'discount' : 'accent'}
            isRounded
            onClick={onSelectProvider}
          >
            {t('search:provider_item.see_schedules')}
          </Button>
        </div>
      </div>
    </div>
  );
};

ProviderItem.propTypes = propTypes;

export default withTranslation('general')(ProviderItem);
