/* global OpenPay */
import { decamelizeKeys } from 'humps';
import store from '@/store';

const openPayTokenParams = (cardParams) => ({
  ...decamelizeKeys(cardParams),
  expiration_year: String(cardParams.expirationYear).substring(2),
});

const mappedOpenPayResponse = (opResponse) => ({
  credit_card_token: opResponse.data.id,
  card_number: opResponse.data.card.card_number,
  card_holder_name: opResponse.data.card.holder_name,
  card_exp_month: opResponse.data.card.expiration_month,
  card_exp_year: opResponse.data.card.expiration_year,
  card_brand: opResponse.data.card.brand,
});

export function openPayCreateToken(cardParams) {
  const { env } = store.getState().whitelabelConfig;
  if (!env.openPay || !env.openPay.enabled) return Promise.resolve(undefined);

  return new Promise((resolve, reject) => {
    OpenPay.token.create(
      openPayTokenParams(cardParams),
      (response) => resolve(mappedOpenPayResponse(response)),
      (error) => reject(error),
    );
  });
}

export function getCardBrand(cardNumber) {
  const brand = OpenPay.card.cardType(cardNumber || '');

  return brand.length ? brand : 'NA';
}

/**
 * Gets OpenPay device session id for antifraud (https://www.openpay.mx/docs/fraud-tool.html)
 * @return {String} Device session id
 */
export function getDeviceSessionId() {
  return OpenPay.deviceData.setup();
}
