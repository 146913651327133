import React from 'react';
import PropTypes from 'prop-types';
import PixForm from '../PixForm';
import { PseStrategyProvider } from '../../../../payments/react/methods/pse/context/strategy';
import {
  kushkiPseStrategy,
  mercadoPagoPseStrategy,
} from '../../../../payments/core/methods/pse/strategies';
import TransferForm from '../TransferForm';
import EvertecForm from '../EvertecForm';

const pseStrategies = {
  kushki: kushkiPseStrategy,
  mercadopago: mercadoPagoPseStrategy,
};

const transferForms = {
  pix: () => <PixForm />,
  pse: ({ selectedPaymentMethod, submitTransfer }) => (
    <PseStrategyProvider
      strategy={
        selectedPaymentMethod?.engine
          ? pseStrategies[selectedPaymentMethod?.engine]
          : pseStrategies.kushki
      }
    >
      <TransferForm onSubmit={submitTransfer} />
    </PseStrategyProvider>
  ),
};

/**
 * TransferFormStrategy component responsible for rendering the appropriate transfer form
 * based on the selected payment provider.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.selectedPaymentMethod - The selected payment method details.
 * @param {Function} props.submitTransfer - The function to call when the transfer is submitted.
 * @returns {React.Component} - The corresponding transfer form component.
 */
const TransferFormStrategy = ({ selectedPaymentMethod, submitTransfer }) => {
  const { engine, provider } = selectedPaymentMethod;
  // If the provider is evertec, we should render the EvertecForm
  // because evertec has its own payment flow
  if (engine === 'evertec') {
    return <EvertecForm />;
  }
  // Get the form based on the provider
  const TransferFormComponent = transferForms[provider] || transferForms.pse;
  return (
    <TransferFormComponent
      selectedPaymentMethod={selectedPaymentMethod}
      submitTransfer={submitTransfer}
    />
  );
};

TransferFormStrategy.propTypes = {
  selectedPaymentMethod: PropTypes.shape({
    engine: PropTypes.string,
    provider: PropTypes.string,
  }).isRequired,
  submitTransfer: PropTypes.func.isRequired,
};

export default TransferFormStrategy;
