import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import config from 'config/environment';
import PricingRow from '../../ui/atoms/PricingRow';

const propTypes = {
  carbon: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  installmentsFee: PropTypes.number.isRequired,
  insurance: PropTypes.number.isRequired,
  markup: PropTypes.number.isRequired,
  serviceLevel: PropTypes.shape({
    name: PropTypes.string,
    total: PropTypes.number,
  }),
};

/**
 * Return component that contains the breakdown and total of extra prices for the travel.
 * @param {Object} props - Component props.
 * @param {Number} props.carbon - Price of carbon credits.
 * @param {Number} props.total - Total price of all extras.
 * @param {Number} props.markup - Service fee markup.
 * @param {Number} props.insurance - Price of travel insurance.
 * @param {Number} props.installmentsFee - Additional cost for deferred payments.
 * @param {Object} props.serviceLevel - Service level details including name and total cost.
 * @returns {JSX.Element}
 */
const PurchasePricingExtras = ({
  carbon,
  total,
  markup,
  insurance,
  installmentsFee,
  serviceLevel,
}) => {
  const { t } = useTranslation('purchase');
  if (total <= 0) return null;

  return (
    <div className="section-content">
      {markup > 0 && (
        <PricingRow
          label={t('label.service_charge')}
          tooltipContent={t('text.working_for_a_better_shopping_experience')}
          price={markup}
          currency={getCurrencySuffix()}
        />
      )}

      {serviceLevel.total > 0 && (
        <PricingRow
          label={t(`service-levels.${serviceLevel.name}`, {
            context: 'title',
          })}
          price={serviceLevel.total}
          currency={getCurrencySuffix()}
          tooltipContent={t(`text.service_level_tooltip`, {
            context: serviceLevel?.name,
          })}
        />
      )}

      {insurance > 0 && (
        <PricingRow
          label={t('label.medical_and_legal_assistance', { context: config.brand })}
          price={insurance}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
        />
      )}

      {carbon > 0 && (
        <PricingRow
          label={t('label.carbon_offset', { context: config.brand })}
          price={carbon}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
        />
      )}

      {installmentsFee > 0 && (
        <PricingRow
          label={t('label.deferred_payment_fee')}
          price={installmentsFee}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
        />
      )}

      <PricingRow
        label={t('label.additional')}
        price={total}
        currency={getCurrencySuffix()}
        sign={getCurrencyPrefix()}
        isMain
      />
    </div>
  );
};

PurchasePricingExtras.defaultProps = {
  serviceLevel: {
    name: '',
    total: 0,
  },
};

PurchasePricingExtras.propTypes = propTypes;

export default PurchasePricingExtras;
