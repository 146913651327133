import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Link } from '@reservamos/elements';

/**
 * ManualInvoicing component
 *
 * This component displays a message with a contact email for manual invoicing.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.billingEmail - The billing email address
 * @returns {JSX.Element} The rendered component
 */
function ManualInvoicing({ billingEmail }) {
  const { t } = useTranslation('invoicing');

  return (
    <Spacing size="XS">
      <Text color="grayLight" size="M">
        {t('manual.contact')}

        <Link
          href={`mailto:${billingEmail}`}
          text={billingEmail}
          type="primary"
          weight="bold"
          size="M"
          newTab
        />
      </Text>
    </Spacing>
  );
}

ManualInvoicing.propTypes = {
  billingEmail: PropTypes.string.isRequired,
};

export default ManualInvoicing;
