import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spacing, Text, Icon, IconText, Link } from '@reservamos/elements';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Trans, useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';
import { getPurchase } from '@/actions/purchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import LoadingScreen from '../LoadingScreen';
import Header from '../Header';
import BrandLogo from '../BrandLogo';
import InvoicingProcess from './InvoicingProcess';
import InvoicingForm from './InvoicingForm';
import 'styles/components/Invoicing';

/**
 * Invoicing component
 *
 * This component handles the invoicing process. It fetches the purchase data
 * if a token is present in the URL and displays the appropriate form or process
 * based on the loaded state.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
const Invoicing = () => {
  const env = useWhitelabelEnvs();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const { loaded } = usePurchase();
  const { t } = useTranslation('invoicing');

  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');

  const { email: billingEmail, url: billingUrl, rfc } = env.billing;

  const invoiceBullets = [1, 2, 3];
  const noteBullets = [1, 2];
  const reminderBullets = [1, 2];

  useEffect(() => {
    if (token && !loaded) {
      dispatch(getPurchase(token));
    }
  }, [dispatch, loaded, token]);

  if (!loaded && token) return <LoadingScreen />;

  return (
    <div className="l-purchase">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <div className="home-container">
        <Spacing vertical size="XL">
          <Text color="grayMedium" size="L" weight="bold" textAlign="left">
            {t('title')}
          </Text>
          <Spacing vertical>
            <Text weight="bold">{t('disclaimer.header')}</Text>
            <ul>
              <Spacing size="XS" vertical>
                {invoiceBullets.map((bullet) => (
                  <li key={bullet}>
                    <IconText
                      label={t('disclaimer.bullet', { context: String(bullet) })}
                      iconType="Dot"
                      iconColor="grayMedium"
                      fontSize="M"
                      iconSize="S"
                    />
                  </li>
                ))}
              </Spacing>
            </ul>
            <Spacing size="XL" isResponsive responsiveSize="M">
              <Spacing vertical>
                <Text weight="bold">{t('note.header')}</Text>
                <ul>
                  <Spacing size="S" vertical>
                    {noteBullets.map((bullet) => (
                      <li key={bullet}>
                        <Spacing alignItems="center">
                          <Icon size="S" color="grayMedium" type="Dot" />
                          <Trans
                            t={t}
                            i18nKey="note.bullet"
                            tOptions={{ context: String(bullet) }}
                            components={{
                              text: <Text size="M">placeholer</Text>,
                              link: (
                                <Link
                                  href={`mailto:${billingEmail}`}
                                  text={billingEmail}
                                  type="accent"
                                  weight="semibold"
                                  size="M"
                                  newTab
                                />
                              ),
                            }}
                          />
                        </Spacing>
                      </li>
                    ))}
                  </Spacing>
                </ul>
              </Spacing>
              <Spacing vertical>
                <Text weight="bold">{t('reminder.header')}</Text>
                <ul>
                  <Spacing size="S" vertical>
                    {reminderBullets.map((bullet) => (
                      <li key={bullet}>
                        <IconText
                          label={t('reminder.bullet', { context: String(bullet) })}
                          iconType="Dot"
                          iconColor="grayMedium"
                          fontSize="M"
                          iconSize="S"
                        />
                      </li>
                    ))}
                  </Spacing>
                </ul>
              </Spacing>
            </Spacing>
          </Spacing>
          {token ? (
            <InvoicingProcess billingEmail={billingEmail} billingUrl={billingUrl} rfc={rfc} />
          ) : (
            <InvoicingForm />
          )}
        </Spacing>
      </div>
    </div>
  );
};

export default Invoicing;
