import React, { Component } from 'react';
import PropTypes from 'prop-types';
import environment from 'config/environment';
import { FlatButton, Icon, Spacing, Text } from '@reservamos/elements';
import { Trans, withTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/Helpers';
import { UserContext } from 'components/Auth0/UserProvider';
import CoinSvg from 'images/brands/reservamos/wallet/coin.svg';
import CheckoutWalletCheckbox from './CheckoutWalletCheckbox';
import CheckoutWalletInput from './CheckoutWalletInput';

function checkboxI18nContext(loadingWallet, usingWallet) {
  if (loadingWallet) return 'loading';
  if (usingWallet) return 'using';

  return '';
}

class CheckoutWallet extends Component {
  componentDidUpdate(prevProps) {
    const { user, updatePurchaseUser, purchaseToken, walletType } = this.props;
    const validWalletType = walletType !== 'doters' && walletType !== 'costapass';
    if (validWalletType && !prevProps.user && user) {
      updatePurchaseUser(purchaseToken);
    }
  }

  render() {
    const {
      selectedPaymentOption,
      purchaseToken,
      setUsingWallet,
      updatingWallet,
      usingWallet,
      walletBalanceUsed,
      maxUsableBalance,
      chosenSpPassengerId,
      total,
      isExchange,
      user,
      t,
      walletType,
      walletBalanceTentative, // ? This is the amount that we are using from the wallet.
      walletAmount, // ? This is the amount that will be generated with the purchase based on the total.
    } = this.props;

    const userContext = this.context;

    const { isAuthenticated, loginWithRedirect, user: reservamosUser } = userContext;
    const { walletBalance: reservamosWalletBalance } = reservamosUser;

    const loadingWallet = updatingWallet;

    if (!user && walletType !== 'cashbackWallet') return null;
    if (walletType === 'doters' || walletType === 'costapass') return null;
    if (selectedPaymentOption === 'oxxo') return null;
    const walletBalance =
      user && walletType !== 'cashackwallet' ? user.availableKms : reservamosWalletBalance;

    const calculatedMaxUsableBalance = total - 10;

    const usableBalance =
      walletBalance >= (maxUsableBalance || calculatedMaxUsableBalance)
        ? maxUsableBalance || calculatedMaxUsableBalance
        : walletBalance;

    const isNotCashbackWallet = walletType !== 'cashbackWallet';

    let wallet;
    let canUsePoints = total !== 0;

    const isCashbackWallet = walletType === 'cashbackWallet';

    if (!chosenSpPassengerId && isNotCashbackWallet) {
      wallet = <Text>{t('user_not_a_passenger', { context: environment.brand })}</Text>;
      canUsePoints = false;
    } else if (usableBalance <= 0 && isNotCashbackWallet) {
      wallet = <Text>{t('no_wallet_funds_message')}</Text>;
      canUsePoints = false;
    } else if (usingWallet && isNotCashbackWallet) {
      wallet = (
        <CheckoutWalletCheckbox
          checkboxContext={checkboxI18nContext}
          setUsingWallet={setUsingWallet}
          loadingWallet={loadingWallet}
          usingWallet={usingWallet}
          usableBalance={usableBalance}
          walletBalanceUsed={walletBalanceUsed}
          purchaseToken={purchaseToken}
        />
      );
    } else if (isCashbackWallet) {
      if (isAuthenticated) {
        wallet = (
          <>
            <Spacing size="XS" alignItems="center" justifyContent="flex-start" responsiveSize="XS">
              <Icon type={CoinSvg} size="M" />
              <Text size="M" color="gray-dark">
                {t('cashback_wallet_checkbox_info', {
                  walletAmount: formatCurrency(walletAmount, 2),
                })}
              </Text>
            </Spacing>
            <CheckoutWalletCheckbox
              checkboxContext={checkboxI18nContext}
              setUsingWallet={setUsingWallet}
              loadingWallet={loadingWallet}
              usingWallet={Boolean(walletBalanceTentative)}
              usableBalance={usableBalance}
              walletBalanceUsed={walletBalanceUsed}
              purchaseToken={purchaseToken}
              walletAmount={walletAmount}
              isCashbackWallet={isCashbackWallet}
              walletBalanceTentative={walletBalanceTentative}
            />
          </>
        );
      } else {
        wallet = (
          <Trans
            t={t}
            tOptions={{
              context: isAuthenticated ? '' : 'unauthenticated',
              cashbackAmount: formatCurrency(walletAmount, 2),
              currencyCode: 'MXN',
            }}
            i18nKey="cashback_wallet_unauthenticated"
            components={{
              cta: (
                <FlatButton
                  type="accent"
                  onClick={() =>
                    loginWithRedirect({ appState: { targetUrl: window.location.pathname } })
                  }
                >
                  placeholder
                </FlatButton>
              ),
              text: (
                <Text color="grayMedium" size="S">
                  placeholer
                </Text>
              ),
            }}
          />
        );
      }
    } else {
      wallet = (
        <CheckoutWalletInput
          checkboxContext={checkboxI18nContext}
          setUsingWallet={setUsingWallet}
          loadingWallet={loadingWallet}
          usableBalance={usableBalance}
          purchaseToken={purchaseToken}
        />
      );
    }

    if (isExchange && !canUsePoints && isNotCashbackWallet) return null;

    return (
      <div className="checkout-section">
        <Spacing vertical size="S">
          <Text weight="bold">{t(isCashbackWallet ? 'cashback_wallet_name' : 'wallet_name')}</Text>
          {wallet}
        </Spacing>
      </div>
    );
  }
}

CheckoutWallet.contextType = UserContext;

CheckoutWallet.propTypes = {
  selectedPaymentOption: PropTypes.string.isRequired,
  usingWallet: PropTypes.bool.isRequired,
  updatingWallet: PropTypes.bool.isRequired,
  purchaseToken: PropTypes.string.isRequired,
  maxUsableBalance: PropTypes.number.isRequired,
  setUsingWallet: PropTypes.func.isRequired,
  updatePurchaseUser: PropTypes.func.isRequired,
  walletBalanceUsed: PropTypes.number.isRequired,
  isExchange: PropTypes.bool,
  total: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    spCard: PropTypes.string.isRequired,
    availableKms: PropTypes.number.isRequired,
  }),
  chosenSpPassengerId: PropTypes.string,
  t: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  walletAmount: PropTypes.number,
  walletBalanceTentative: PropTypes.number,
};

CheckoutWallet.defaultProps = {
  walletAmount: 0,
  walletBalanceTentative: 0,
};

export default withTranslation('loyalty')(CheckoutWallet);
