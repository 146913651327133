import { getTripDiscountPercent } from 'utils/Helpers';
import { hasMultipleCarriers, getPlaceType } from 'utils/Reserbus';
import discountGFA from 'images/brands-unique/gfa/dynamic-prices-logo.png';
import etnDiscount from 'images/brands-unique/etn/discountETN.png';

function getTransportType(slug) {
  if (/^flight-/.test(slug)) {
    return 'flight';
  }
  if (/-multi-/.test(slug)) {
    return 'mixed';
  }

  return 'bus';
}

function getMixedProviderId(fragments, lines) {
  const carrierIds = fragments.map(({ transportType, lineId, legs }) => {
    if (transportType === 'bus') {
      const line = lines[lineId];
      const { transporterId, copyrightProtected } = line;

      return copyrightProtected ? transporterId : lineId;
    }
    if (hasMultipleCarriers(legs)) {
      return 'multicarrier';
    }

    return legs[0].carrierId;
  });

  return carrierIds.sort().join('-');
}

// Mutates the trip object
function addBusProperties(trip, terminals, cities, lines) {
  const origin = terminals[trip.originId];
  const destination = terminals[trip.destinationId];
  const originCity = cities[origin.cityId];
  const destinationCity = cities[destination.cityId];
  const line = lines[trip.lineId];
  trip.providerId = line.copyrightProtected ? line.redirectionInfo.mobile.shortUrl : trip.lineId;
  trip.origin = origin;
  trip.origin.countryName = originCity && originCity.country;
  trip.destination = destination;
  trip.destination.countryName = destinationCity && destinationCity.country;
  trip.line = line;
}

// Mutates the trip (flight) object
function addFlightProperties(flight, airports, cities, carriers, fareServices) {
  const origin = airports[flight.originId];
  const destination = airports[flight.destinationId];
  const originCity = cities[origin.cityId];
  const destinationCity = cities[destination.cityId];
  const multicarrierFlight = hasMultipleCarriers(flight.legs);

  flight.providerId = multicarrierFlight ? 'multicarrier' : flight.legs[0].carrierId;
  flight.transportType = 'flight';
  flight.origin = origin;
  flight.origin.countryName = originCity && originCity.country;
  flight.destination = destination;
  flight.destination.countryName = destinationCity && destinationCity.country;
  flight.carrier = carriers[flight.legs[0].carrierId];
  flight.services = fareServices[flight.fareService];

  flight.legs.forEach((leg) => {
    leg.transportType = 'flight';
    leg.origin = airports[leg.originId];
    leg.destination = airports[leg.destinationId];
    leg.carrier = carriers[leg.carrierId];
  });

  flight.stopoverPlace = {
    type: 'airport',
    hasTransit: false,
    sameLine: !multicarrierFlight,
  };
}

// Mutates the trip (itinerary) object
function addMixedProperties(itinerary, innerCityConnections, busProperties, flightProperties) {
  const [terminals, cities, lines] = busProperties;
  const [airports] = flightProperties;
  const isTerminal = (placeId) => getPlaceType(placeId) === 'terminal';
  const [firstFragment, secondFragment] = itinerary.fragments;

  itinerary.providerId = getMixedProviderId(itinerary.fragments, lines);
  itinerary.transportType = 'mixed';
  itinerary.isBuyable = true;
  itinerary.origin = isTerminal(itinerary.originId)
    ? terminals[itinerary.originId]
    : airports[itinerary.originId];
  itinerary.origin.countryName = cities[itinerary.origin.cityId].country;
  itinerary.destination = isTerminal(itinerary.destinationId)
    ? terminals[itinerary.destinationId]
    : airports[itinerary.destinationId];
  itinerary.destination.countryName = cities[itinerary.destination.cityId].country;
  itinerary.fragments.forEach((fragment) => {
    const { connectionIds } = fragment;

    fragment.innerCityConnection = innerCityConnections[connectionIds && connectionIds[0]];
    fragment.origin = isTerminal(fragment.originId)
      ? terminals[fragment.originId]
      : airports[fragment.originId];
    fragment.destination = isTerminal(fragment.destinationId)
      ? terminals[fragment.destinationId]
      : airports[fragment.destinationId];

    if (fragment.transportType === 'bus') {
      addBusProperties(fragment, ...busProperties);
    } else {
      addFlightProperties(fragment, ...flightProperties);
    }
  });
  itinerary.stopoverPlace = {
    type: getPlaceType(secondFragment.origin.id),
    hasTransit: !!firstFragment.connectionIds.length,
    sameLine: itinerary.fragments.every(
      ({ providerId }) => providerId === firstFragment.providerId,
    ),
    ...secondFragment.origin,
  };
}

// Mutates the trip object
export default function parseTrip({
  trip,
  cities,
  terminals,
  airports,
  lines,
  carriers,
  innerCityConnections,
  fareServices,
}) {
  const departureType = getTransportType(trip.id);

  if (departureType === 'bus') {
    addBusProperties(trip, terminals, cities, lines);
  }
  if (departureType === 'flight') {
    addFlightProperties(trip, airports, cities, carriers, fareServices);
  }
  if (departureType === 'mixed') {
    addMixedProperties(
      trip,
      innerCityConnections,
      [terminals, cities, lines],
      [airports, cities, carriers, fareServices],
    );
  }
}

/**
 * Calculates the base trip discount percentage.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.trip - The trip object.
 * @param {string} [options.way='departure'] - The trip way.
 * @param {boolean} options.isRoundTrip - Indicates if it's a round trip.
 * @returns {number} The base trip discount percentage.
 */
export const getBaseTripDiscountPercent = ({ trip, way = 'departure', isRoundTrip }) => {
  if (!trip) return '';
  let pricingKey = 'pricing';
  if (isRoundTrip)
    pricingKey = way === 'departure' ? 'departureRoundTripPricing' : 'roundTripPricing';
  return getTripDiscountPercent({
    tripTotal: trip[pricingKey].total,
    way,
    discountAmount: trip[pricingKey].discountBase,
  });
};

export const discountTypes = {
  dynamic_pricing_single_on_round_trip_discount: {
    inLabel: 'early_discount',
    icon: {
      gfa: discountGFA,
      etn: etnDiscount,
      gho: etnDiscount,
      costaline: etnDiscount,
    },
  },
  dynamic_pricing: {
    inLabel: 'early_discount',
    icon: {
      gfa: discountGFA,
      etn: etnDiscount,
      gho: etnDiscount,
      costaline: etnDiscount,
    },
  },
  early_trip_discount: {
    inLabel: 'early_discount',
    icon: {
      gfa: discountGFA,
      etn: etnDiscount,
      gho: etnDiscount,
      costaline: etnDiscount,
    },
  },
  single_trip_discount: { inLabel: 'digital_discount' },
  departure_on_round_trip_discount: { inLabel: 'digital_discount' },
  single_on_round_trip_discount: { inLabel: 'digital_discount' },
};
