import React from 'react';
import PropTypes from 'prop-types';
import { Link, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import ServiceLevelOption from './ServiceLevelOption';

/**
 * ServiceLevels component
 *
 * @param {Object} props - Component props
 * @param {Function} props.updateServiceLevel - Function to update the service level
 * @param {boolean} props.isUpdating - Flag indicating if the service level is being updated
 * @param {Object} props.selectedServiceLevel - The currently selected service level
 * @param {Array} props.serviceLevels - List of available service levels
 * @param {boolean} props.areServiceLevelsEnabled - Flag indicating if service levels are enabled
 * @param {string} props.token - Authentication token
 * @returns {JSX.Element|null} The rendered component or null if service levels are not enabled
 */
const ServiceLevels = ({
  updateServiceLevel,
  isUpdating,
  selectedServiceLevel,
  serviceLevels,
  areServiceLevelsEnabled,
  token,
}) => {
  const { t } = useTranslation('purchase');

  if (!areServiceLevelsEnabled) return null;

  /**
   * Handles the change event for the service level option
   *
   * @param {Object} element - The event object
   */
  function handleChange(element) {
    const maxLevelId = serviceLevels.find((sl) => sl.name === 'max').id;
    const basicLevelId = serviceLevels.find((sl) => sl.name === 'basic').id;

    if (element.target.checked) {
      updateServiceLevel(token, { serviceLevelId: maxLevelId });
    } else {
      updateServiceLevel(token, { serviceLevelId: basicLevelId });
    }
  }

  return (
    <Spacing vertical size="M">
      <Spacing vertical size="XS">
        <Text weight="bold">{t('service-levels.title')}</Text>
        <Text mobileSize="S" color="grayMedium" elementType="span">
          <Spacing vertical size="XS">
            {t('service-levels.subtitle')}
            <Link
              href="https://contenido.reservamos.mx/cobertura-boletoflex/"
              text={t('service-levels.details-link')}
              type="info"
              weight="semibold"
              size="S"
              newTab
            />
          </Spacing>
        </Text>
      </Spacing>

      <Spacing justifyContent="space-between" alignItems="flex-start" isResponsive>
        <ServiceLevelOption coverage="basic" />
        <ServiceLevelOption
          coverage="max"
          isChecked={selectedServiceLevel?.name !== 'basic'}
          isUpdating={isUpdating}
          // eslint-disable-next-line react/jsx-no-bind
          handleChange={handleChange}
          serviceLevels={serviceLevels}
        />
      </Spacing>
      <Text weight="bold" color="grayMedium" size="S" italic>
        {t('service-levels.terms')}
      </Text>
    </Spacing>
  );
};

ServiceLevels.propTypes = {
  updateServiceLevel: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  selectedServiceLevel: PropTypes.shape({
    name: PropTypes.string,
  }),
  serviceLevels: PropTypes.array,
  token: PropTypes.string,
  areServiceLevelsEnabled: PropTypes.bool.isRequired,
};

export default ServiceLevels;
