/* eslint-disable no-await-in-loop */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getDistinctId,
  identifyUser,
  userAlias,
  resetUser,
  trackUserAccountEvent,
} from 'user-analytics';
import fetchUserProfile from 'utils/fetchUserProfile';
import Cookies from 'js-cookie';
import { setUserAuthToken } from 'js-api-client';
import OneSignal from 'react-onesignal';

export const UserContext = createContext({});

/**
 * Checks if the distinct ID contains a pipe character or is in the format of an email.
 * If either condition is met, the function returns true, indicating that a profile reset is needed.
 *
 * @returns {boolean} - Returns true if the distinct ID contains a pipe character or is an email, otherwise false.
 */
function needsReset() {
  return getDistinctId().includes('|') || /[\w-]+@([\w-]+\.)+[\w-]{2,4}/g.test(getDistinctId());
}

function UserProvider({ children, config }) {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});

  const { cookieDomain, cookieName, profileUrl } = config;

  const {
    getAccessTokenSilently,
    isAuthenticated,
    logout,
    loginWithRedirect,
    user: auth0User,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        let retries = 3;
        while (retries > 0) {
          try {
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();
            const userData = await fetchUserProfile(accessToken)
              .then((data) => {
                const mixpanelProfileProperties = {
                  '$email': auth0User.email,
                  '$first_name': auth0User.given_name,
                  '$last_name': auth0User.family_name,
                  '$name': auth0User.name,
                  'Wallet Balance': data.profile.walletBalance,
                  'User Type': 'Registered',
                  '$onesignal_user_id': auth0User.email,
                };

                OneSignal.setExternalUserId(auth0User.email);

                if (auth0User.email) OneSignal.setEmail(auth0User.email);

                const loginType = auth0User.sub.split('|')[0];

                if (auth0User.first_login) {
                  userAlias(auth0User.email, getDistinctId(), mixpanelProfileProperties);
                  trackUserAccountEvent('Sign Up', { loginType });
                } else {
                  identifyUser(auth0User.email, mixpanelProfileProperties);
                  trackUserAccountEvent('Log In', {
                    loginType,
                  });
                }

                return data;
              })
              .catch((error) => {
                throw new Error(error);
              });

            setUserAuthToken(accessToken);
            setUser({ ...camelizeKeys(auth0User), ...userData.profile });

            Cookies.set(cookieName, accessToken, {
              expires: 3,
              domain: cookieDomain,
            });

            break;
          } catch (error) {
            retries -= 1;
            if (retries === 0) throw new Error(error);
          } finally {
            setIsLoading(false);
          }
        }
      })();
    } else if (needsReset()) {
      resetUser();
    }
  }, [
    auth0User,
    auth0User?.email,
    auth0User?.family_name,
    auth0User?.first_login,
    auth0User?.given_name,
    auth0User?.name,
    auth0User?.sub,
    cookieDomain,
    cookieName,
    getAccessTokenSilently,
    isAuthenticated,
  ]);

  function handleLogout() {
    Cookies.remove(cookieName, {
      domain: cookieDomain,
    });

    trackUserAccountEvent('Log Out', { loginType: user.sub.split('|')[0] });
    resetUser();

    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  function handleLogin() {
    return loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated,
        logout: handleLogout,
        loginWithRedirect: handleLogin,
        isLoading,
        profileUrl,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  config: PropTypes.shape({
    cookieName: PropTypes.string.isRequired,
    cookieDomain: PropTypes.string.isRequired,
    profileUrl: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default UserProvider;
