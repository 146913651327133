import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

function makeCls(transportType) {
  switch (transportType) {
    case 'bus':
      return 'bus';
    case 'flight':
      return 'plane';
    default:
      return '';
  }
}

const propTypes = {
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  transportType: PropTypes.string,
  type: PropTypes.string.isRequired,
  index: PropTypes.number,
  brand: PropTypes.string,
  originDetails: PropTypes.object,
};

const defaultProps = {
  transportType: 'bus',
};

const PlaceItem = ({
  description,
  time,
  title,
  transportType,
  type,
  index,
  brand,
  originDetails,
}) => {
  const { t } = useTranslation('trips');
  const transportCls = makeCls(transportType);
  const hasCoordinates = originDetails.coordinates.lat || originDetails.coordinates.long;
  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  return (
    <>
      {brand === 'brasilia' && (
        <Text weight="semibold">
          {t('route')} {index}
        </Text>
      )}
      <div className={`route-detail-row ${type}`}>
        <div className="rd-left">
          <p>
            <b>{time}</b>
          </p>
        </div>

        <div className="rd-center">
          <Icon type="Bus" color="primary" size="L" />
        </div>

        <div className={`rd-right ${transportCls}`}>
          <p>
            <b>{title}</b>
            {showTerminal && <span>{description}</span>}
            {originDetails.address && <p>{originDetails.address}</p>}
          </p>
          {hasCoordinates ? (
            <Link
              newTab
              type="primary"
              size="XS"
              href={`https://www.google.com/maps/search/?api=1&query=${originDetails.coordinates.lat},${originDetails.coordinates.long}`}
              text={t('see_location')}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

PlaceItem.propTypes = propTypes;
PlaceItem.defaultProps = defaultProps;

export default PlaceItem;
